import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import PrintFormGroup from "../../components/PrintFormGroup";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import steps from "../../Assets/data/checkoutStepsData";
import paymentData from "../../Assets/data/paymentData";
import PolicyDetails from "../../components/PolicyDetails";

import GeneralModal from "../../components/GeneralModal";

import _ from "lodash";
import { connect } from "react-redux";
import { fetchQuote, saveQuote, fetchQuoteFromFirst } from "../../redux/actions/quote";
import { getFormLines, getForms, getPricing, getQuote, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";
import _checkoutData from "../../Assets/data/checkoutData";
import EpicAPI from "../../Assets/api/epic";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";

import FirstAPI from "../../Assets/api/first";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
    pricing: getPricing(state),
  };
};
export class CheckoutPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 4,
      steps: steps,
      timeMethod: "",
      firstQuoteNumber: null,
      loadingQuote: true,
      isLoading: false,
      prevPage: "/checkout/review",
      nextPage: "/checkout/payment-process",
    };
    this.quoteNumberInterval = null;
  }
  async componentDidMount() {
    const { quote, fetchQuote, forms, fetchForms, lines, fetchQuoteFromFirst, locale, fetchLocale } = this.props;
    const { firstQuoteNumber } = this.state;
    if (!locale) fetchLocale();
    if (!quote || _.isEmpty(quote)) fetchQuote();
    else {
      if (quote.firstQuoteNumber === "error") {
        // fetchQuoteFromFirst(quote);
        const quoteNumber = await FirstAPI.getQuoteNumber(quote, locale);
        this.setQuoteNumber(quoteNumber);
      }
      if (!firstQuoteNumber && !quote.firstQuoteNumber) {
        // fetchQuoteFromFirst(quote);
        const quoteNumber = await FirstAPI.getQuoteNumber(quote, locale);
        this.setQuoteNumber(quoteNumber);
      } else {
        this.setQuoteNumber(quote.firstQuoteNumber);
      }
      this.setQuoteState(quote);
    }

    if (lines && !_.isEmpty(lines)) {
      fetchForms(lines[0]);
      this.setState(lines);
    } else this.getLineIDs();

    if (forms && !_.isEmpty(forms)) this.setFormsState(forms);

    this.watchQuote();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { quote, forms, lines, fetchQuoteFromFirst, locale } = this.props;
    const { firstQuoteNumber } = this.state;
    if (!locale) fetchLocale();
    if (!_.isEqual(lines, prevProps.lines)) this.setState(lines);
    if (!_.isEqual(quote, prevProps.quote)) {
      if (quote && !quote.firstQuoteNumber) {
        // fetchQuoteFromFirst(quote);
        const quoteNumber = await FirstAPI.getQuoteNumber(quote, locale);
        console.log({ quoteNumber });
        this.setQuoteNumber(quoteNumber);
      }
      if (quote.firstQuoteNumber === "error") {
        const quoteNumber = await FirstAPI.getQuoteNumber(quote, locale);
        console.log({ quoteNumber });
        this.setQuoteNumber(quoteNumber);
      }
      this.setQuoteState(quote);
    }
    if (!_.isEqual(forms, prevProps.forms)) this.setFormsState(forms);
    if (this.state.quote && quote.timeMethod && quote.timeMethod !== this.state.timeMethod) {
      this.setState({ timeMethod: quote.timeMethod });
    }
    if (this.state.quote !== prevState.quote) {
      if (this.state.quote.firstQuoteNumber) {
        //this.setQuoteNumber();
      }
    }
    if (firstQuoteNumber && firstQuoteNumber !== prevState.firstQuoteNumber) {
      //console.log(firstQuoteNumber);
      //this.setState({firstQuoteNumber, loadingQuote: false})
    }
    if (quote && !!quote.firstQuoteNumber && quote.firstQuoteNumber !== firstQuoteNumber) {
      //console.log(quote.firstQuoteNumber);
      //this.setQuoteNumber();
    }
  }

  componentWillUnmount() {
    clearInterval(this.quoteNumberInterval);
  }

  watchQuote = () => {
    this.quoteNumberInterval = setInterval(() => {
      const { quote } = this.props;
      if (
        quote
        // && quote.location !== "QC"
      ) {
        if (!!quote && quote.firstQuoteNumber && quote.firstQuoteNumber !== "error") {
          clearInterval(this.quoteNumberInterval);
          this.setQuoteNumber(quote.firstQuoteNumber);
        }
      } else {
        //TODO: save user policies? flag somehow
        // if (quote && quote.location === "QC") {
        //   _.each(quote.activePolicies, (policy) => {
        //     EpicAPI.insertActivity({
        //       clientID: quote.clientID,
        //       policyID: policy,
        //       code: "REFR",
        //       type: "Policy",
        //       province: quote.location,
        //     });
        //   });
        //   clearInterval(this.quoteNumberInterval);
        //   if (quote.firstQuoteNumber) {
        //     this.setQuoteNumber(quote.firstQuoteNumber);
        //   }
        // }
      }
    }, 1000);
  };

  setQuoteNumber(quoteNumber) {
    const { quote, saveQuote } = this.props;
    const { firstQuoteNumber } = this.state;
    // let _firstQuoteNumber;
    if (!firstQuoteNumber && quoteNumber) {
      // _firstQuoteNumber = quote.firstQuoteNumber;
      this.setState({ firstQuoteNumber: quoteNumber.quoteNumber, loadingQuote: false });
      const _quote = { ...quote, firstQuoteNumber: quoteNumber.quoteNumber };
      saveQuote(_quote);
    }
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }

  setFormsState(forms) {
    let checkoutData = _checkoutData;
    // console.log(forms);
    this.setState({
      forms,
      checkoutData,
    });
  }

  getLineIDs = async () => {
    const { quote, saveFormLines } = this.props;
    let lines = [];
    if (quote && quote.activePolicies) {
      for (let p of quote.activePolicies) {
        let lineID = await EpicAPI.getLine(p);
        lines.push(lineID);
      }
    }
    saveFormLines(lines);
  };

  validateForm() {
    const { quote } = this.props;
    if (quote && quote.timeMethod && quote.timeMethod !== "") {
      return true;
    } else {
      return false;
    }
  }

  handleButtonChange = (id, val) => {
    // console.log(this.state)
    this.setState({
      [id]: val,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.history.push(this.state.nextPage);
  };

  render() {
    const { loadingQuote, firstQuoteNumber } = this.state;
    const { quote, locale } = this.props;
    const qc = quote && quote.location === "QC" && true;

    return (
      <Container className="Payment">
        <Row noGutters>
          <Col className="quoteFormWrap">
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} display={quote && !quote.flaggedUser} />
            <div className="formDescription">
              <div className="mobilePricing">
                <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                <PolicyDetails hideEdit={true} />
              </div>
              <h3>{translateString(locale, "Payment")}</h3>
            </div>
            {/* {qc && (
              <div className="Line Gray top">
                <p>
                  {translateString(
                    locale,
                    "Your policy request has been received and you will be contacted by our office to complete this purchase as per Quebec legislation"
                  )}
                </p>
                <FormBottomNav
                  disabled={true}
                  isLoading={false}
                  prevText={translateString(locale, "Start Over")}
                  prevPage={"/checkout"}
                  className="Line Green top"
                />
              </div>
            )} */}
            {quote && quote.flaggedUser && (
              <div className="Line Gray top">
                <p className="FormBottomNav">{translateString(locale, "Generating a Quote")}...</p>
                <div className="FormBottomNav"></div>
              </div>
            )}
            {loadingQuote &&
              // !qc &&
              quote &&
              !quote.flaggedUser && (
                <div className="Line Gray top">
                  <p>{translateString(locale, "Generating a Quote, please wait")}...</p>
                  <FormBottomNav
                    disabled={true}
                    isLoading={this.state.isLoading}
                    prevText={translateString(locale, "Start Over")}
                    prevPage={"/checkout"}
                    className="Line Green top"
                  />
                </div>
              )}
            {!loadingQuote &&
              firstQuoteNumber !== "error" &&
              // !qc &&
              quote &&
              !quote.flaggedUser && (
                <form onSubmit={this.handleSubmit}>
                  <div className="Line Gray top">
                    <div className="paymentMethod">
                      <PrintFormGroup parent="paymentAddress" data={paymentData} className="formatted" />
                    </div>
                  </div>
                  <FormBottomNav
                    disabled={!this.validateForm()}
                    isLoading={this.state.isLoading}
                    prevText={translateString(locale, "Start Over")}
                    prevPage={"/checkout"}
                    className="Line Green top"
                    required={["Payment method"]}
                  />
                </form>
              )}
            {!loadingQuote &&
              // !qc &&
              firstQuoteNumber === "error" && (
                <form onSubmit={this.handleSubmit}>
                  <div className="Line Gray top">
                    <div className="paymentMethod">
                      {translateString(locale, "We've run into an error loading a new Quote, please refresh the page and try again.")}
                    </div>
                  </div>
                  <div className="tempNext" onClick={() => window.location.reload()}>
                    {translateString(locale, "Reload")}
                  </div>
                </form>
              )}
          </Col>
          <Col lg={"auto"} className="sideBar">
            <PolicyDetails hideEdit={true} />
          </Col>
        </Row>
        <GeneralModal show={quote && quote.flaggedUser}>
          <p>
            {translateString(
              locale,
              "Your application has been referred to an underwriter for review. Once the review has been completed we will contact you with the next steps."
            )}
          </p>
          <Button
            disabled={loadingQuote}
            variant="primary"
            onClick={(e) => {
              window.location.href = "https://www.advocisinsurance.ca/";
            }}>
            {translateString(locale, "OK")}
          </Button>
        </GeneralModal>
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchQuoteFromFirst,
  fetchForms,
  saveForms,
  saveFormLines,
})(CheckoutPayment);
