import React, { Component } from 'react';
import FormBottomNav from '../components/FormBottomNav';
import FormTopNav from '../components/FormTopNav';
import { Row, Col, Container, Button } from 'react-bootstrap';
import _checkoutData from '../Assets/data/checkoutData';
import _checkoutDataD from '../Assets/data/checkoutDataD';
import steps from '../Assets/data/checkoutStepsData';
import PrintFormGroup from '../components/PrintFormGroup';
// import GeneralModal from "../components/GeneralModal";
import PolicyDetails from '../components/PolicyDetails';

import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchQuote, saveQuote } from '../redux/actions/quote';
import { fetchForms, saveFormLines, saveForms } from '../redux/actions/forms';
import { getQuote, getForms, getFormLines, getLocale } from '../redux/reducers';
import { fetchLocale } from '../redux/actions/locale';
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';
import EpicAPI from '../Assets/api/epic';
import xmlescape from 'xml-escape';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state)
  };
};
export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      email: '',
      steps: steps,
      prevPage: false,
      nextPage: '/checkout/information',
      isLoading: false,
      confirmEmail: false,
      Answer1: null,
      Answer2: null,
      Answer3: null,
      Answer4: null,
      Answer5: null,
      Answer6: null,
      Answer7: null,
      DecD1: null,
      DecD2: null,
      DecD3: null,
      DecD4: null,
      DecD5: null,
      DecD6: null,
      DecD7: null,
      isDPolicy: false,
      isOtherPolicy: false,
      TextBox2: '',
      TextBox9: '',
      flaggedUser: false,
      fetchingForms: true,
      checkoutData: _checkoutData,
      checkoutDataD: _checkoutDataD,
      fetchingLines: true,
      Modals: [
        {
          userFlag: false
        }
      ]
    };
  }

  handleModalShow = (key, e) => {
    // e.preventDefault();
    //console.log(e);
    let Modals = { ...this.state.Modals };
    Modals[0][key] = true;
    this.setState({
      Modals
    });
  };

  handleModalClose = (key) => {
    let Modals = { ...this.state.Modals };
    Modals[0][key] = false;
    this.setState({
      Modals
    });
  };

  getPolicyType = (quote) => {
    let policyTypes = quote.policies.map((policy) => policy.code);
    policyTypes.forEach((policy) => {
      switch (policy.substr(0, 2)) {
        case 'EA':
        case 'EB':
        case 'EC':
          if (this.state.isOtherPolicy === false) {
            this.setState({ isOtherPolicy: true });
          }
          break;
        case 'ED':
          if (this.state.isDPolicy === false) {
            this.setState({ isDPolicy: true });
          }
          break;
        default:
          break;
      }
    });
  };

  async componentDidMount() {
    const { quote, fetchQuote, forms } = this.props;

    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setQuoteState(quote);
      this.getPolicyType(quote);
      // this.setState({ flaggedUser: this.setUserFlag(quote) });
      // const Modals = { ...this.state.Modals };
      // Modals[0].userFlag = this.setUserFlag(quote);
      // this.setState({ Modals });
    }

    if (!forms || _.isEmpty(forms)) this.getLineIDs();
    else this.setFormsState(forms);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { quote, forms, lines, locale, fetchLocale } = this.props;

    if (!locale) fetchLocale();
    if (!_.isEqual(lines, prevProps.lines)) this.setState({ lines });
    if (!_.isEqual(quote, prevProps.quote)) {
      this.setQuoteState(quote);
      this.getPolicyType(quote);
      // this.setState({ flaggedUser: this.setUserFlag(quote) });
      // const Modals = { ...this.state.Modals };
      // Modals[0].userFlag = this.setUserFlag(quote);
      // this.setState({ Modals });
    }
    if (!_.isEqual(forms, prevProps.forms)) this.setFormsState(forms);
    //if(this.state.lines !== prevState.lines) {console.log(this.state.lines)};
  }
  setUserFlag = () => {
    const { quote } = this.props;
    const { Answer1, Answer2, Answer3, Answer4, Answer5, Answer6, Answer7, DecD1, DecD2, DecD3, DecD4, DecD5, DecD6, DecD7 } = this.state;
    console.log('flagging user start...');
    let flagged = false;

    const flagUser = () => {
      console.log('user Flagged');
      flagged = true;
      _.each(quote.activePolicies, (policy) => {
        // console.log("adding REFR activity to policy ", policy);
        EpicAPI.insertActivity({
          clientID: quote.clientID,
          policyID: policy,
          code: 'REFR',
          type: 'Policy',
          // pass province to insertActivity, for setting brancCode for Quebec client.
          province: quote.province
        });
      });
    };
    // if first 3 policies and user clicks yes
    if (
      this.state.isOtherPolicy &&
      (!(Answer1 && Answer2 && Answer3 && Answer4 && Answer5 && Answer6 && Answer7) ||
        Answer1 === 'yes' ||
        Answer2 === 'yes' ||
        Answer3 === 'yes' ||
        Answer4 === 'yes' ||
        Answer5 === 'yes' ||
        Answer6 === 'yes' ||
        Answer7 === 'yes')
    ) {
      flagUser();
    }

    // if first 4th (D) policy and user clicks yes
    if (
      this.state.isDPolicy &&
      (!(DecD1 && DecD2 && DecD3 && DecD4 && DecD5 && DecD6 && DecD7) ||
        DecD1 === 'yes' ||
        DecD2 === 'yes' ||
        DecD3 === 'yes' ||
        DecD4 === 'yes' ||
        DecD5 === 'yes' ||
        DecD6 === 'yes' ||
        DecD7 === 'yes')
    ) {
      flagUser();
    }

    if (!flagged) {
      // console.log("user Not flagged");
    }

    this.setState({ flaggedUser: flagged });
    const Modals = { ...this.state.Modals };
    Modals[0].userFlag = flagged;
    this.setState({ Modals });
    return flagged;
  };

  setQuoteState(quote) {
    this.setState({ isLoading: true });
    // console.log("setQuoteState function", quote);
    this.getLineIDs();
    this.setState({
      ...quote
    });
    this.setState({ isLoading: false });
  }

  setFormsState(forms) {
    let checkoutData = _checkoutData;
    let checkoutDataD = _checkoutDataD;
    // console.log('loaded forms');
    this.setState({
      forms,
      checkoutData,
      checkoutDataD,
      fetchingForms: false
    });
  }

  getLineIDs = async () => {
    const { quote, forms, saveFormLines, fetchForms } = this.props;
    let lines = [];
    if (quote && quote.activePolicies) {
      for (let p of quote.activePolicies) {
        let lineID = await EpicAPI.getLine(p);
        lines.push(lineID);
      }
    }
    if (!forms) {
      console.log('fetching forms');
      this.setState({ fetchingForms: true });
    }
    fetchForms(lines[0]);
    saveFormLines(lines);
  };

  validateForm() {
    const { Answer1, Answer2, Answer3, Answer4, Answer5, Answer6, Answer7, DecD1, DecD2, DecD3, DecD4, DecD5, DecD6, DecD7, TextBox2, TextBox9 } =
      this.state;
    // console.log({ Answer1, Answer2, Answer3, Answer4, Answer5, Answer6, Answer7, TextBox2});
    const allNo =
      Answer1 === 'no' && Answer2 === 'no' && Answer3 === 'no' && Answer4 === 'no' && Answer5 === 'no' && Answer6 === 'no' && Answer7 === 'no';
    const allNoD = DecD1 === 'no' && DecD2 === 'no' && DecD3 === 'no' && DecD4 === 'no' && DecD5 === 'no' && DecD6 === 'no' && DecD7 === 'no';
    if (allNo || allNoD) return false;

    const filledAllValues =
      Answer1 === null ||
      Answer1 === '' ||
      Answer2 === null ||
      Answer2 === '' ||
      Answer3 === null ||
      Answer3 === '' ||
      Answer4 === null ||
      Answer4 === '' ||
      Answer5 === null ||
      Answer5 === '' ||
      Answer6 === null ||
      Answer6 === '' ||
      Answer7 === null ||
      Answer7 === '';

    const filledAllValuesD =
      DecD1 === null ||
      DecD1 === '' ||
      DecD2 === null ||
      DecD2 === '' ||
      DecD3 === null ||
      DecD3 === '' ||
      DecD4 === null ||
      DecD4 === '' ||
      DecD5 === null ||
      DecD5 === '' ||
      DecD6 === null ||
      DecD6 === '' ||
      DecD7 === null ||
      DecD7 === '';
    if (this.state.isOtherPolicy && filledAllValues) return true;
    if (this.state.isDPolicy && filledAllValuesD) return true;

    if (this.state.isOtherPolicy) {
      const ifYesAdditionalInfo = TextBox2 === '';
      if (ifYesAdditionalInfo) return true;
    }
    if (this.state.isDPolicy) {
      const ifYesAdditionalInfoD = TextBox9 === '';
      if (ifYesAdditionalInfoD) return true;
    }

    return false;
  }

  handleChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { saveQuote, saveForms, quote } = this.props;
    setTimeout(() => {
      this.setUserFlag();
      const {
        forms,
        Answer1,
        Answer2,
        Answer3,
        Answer4,
        Answer5,
        Answer6,
        Answer7,
        DecD1,
        DecD2,
        DecD3,
        DecD4,
        DecD5,
        DecD6,
        DecD7,
        TextBox2,
        TextBox9,
        flaggedUser
      } = this.state;

      let first_name = '';
      let last_name = '';
      let middleInitial = '';
      if (quote && quote.FirstName && quote.Last) {
        first_name = xmlescape(quote.FirstName);
        last_name = xmlescape(quote.Last);
      }
      if (quote && quote.Middle) {
        middleInitial = xmlescape(quote.Middle[0]);
      }
      let _forms = forms;
      _.each(_forms.screens, (s) => {
        if (s.ScreenName === 'Client Information') {
          _.each(s.fields, (f) => {
            if (f.FieldName === 'FirstName') f.FieldValue = first_name;
            if (f.FieldName === 'Initial') f.FieldValue = middleInitial;
            if (f.FieldName === 'Last') f.FieldValue = last_name;
          });
        }
        if (s.ScreenName === 'Underwriting') {
          _.each(s.fields, (f) => {
            if (f.FieldName === 'Answer1') f.FieldValue = Answer1 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer2') f.FieldValue = Answer2 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer3') f.FieldValue = Answer3 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer4') f.FieldValue = Answer4 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer5') f.FieldValue = Answer5 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer6') f.FieldValue = Answer6 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'Answer7') f.FieldValue = Answer7 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD1') f.FieldValue = DecD1 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD2') f.FieldValue = DecD2 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD3') f.FieldValue = DecD3 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD4') f.FieldValue = DecD4 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD5') f.FieldValue = DecD5 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD6') f.FieldValue = DecD6 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'DecD7') f.FieldValue = DecD7 === 'yes' ? 'Y' : 'N';
            if (f.FieldName === 'TextBox2') f.FieldValue = xmlescape(TextBox2);
            if (f.FieldName === 'TextBox9') f.FieldValue = xmlescape(TextBox9);
          });
        }
      });
      // console.log('_forms', _forms);
      this.setState({ isLoading: true });
      /* resave quote to prevent missing data bug */
      const _quote = { ...quote };
      _quote.Answer1 = Answer1;
      _quote.Answer2 = Answer2;
      _quote.Answer3 = Answer3;
      _quote.Answer4 = Answer4;
      _quote.Answer5 = Answer5;
      _quote.Answer6 = Answer6;
      _quote.Answer7 = Answer7;
      _quote.DecD1 = DecD1;
      _quote.DecD2 = DecD2;
      _quote.DecD3 = DecD3;
      _quote.DecD4 = DecD4;
      _quote.DecD5 = DecD5;
      _quote.DecD6 = DecD6;
      _quote.DecD7 = DecD7;
      _quote.flaggedUser = flaggedUser;
      // console.log("_quote", _quote);
      saveQuote(_quote);
      this.setQuoteState(_quote);
      try {
        // set isloading to true during submitting
        this.setState({ isLoading: true });
        saveForms(_forms)
          .then((d) => this.props.history.push(this.state.nextPage))
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e);
        this.setState({ isLoading: false });
      }
    }, 500);
  };

  render() {
    const { locale } = this.props;
    const {
      checkoutData,
      checkoutDataD,
      fetchingForms,
      Answer1,
      Answer2,
      Answer3,
      Answer4,
      Answer5,
      Answer6,
      Answer7,
      DecD1,
      DecD2,
      DecD3,
      DecD4,
      DecD5,
      DecD6,
      DecD7
    } = this.state;
    const quote = this.props.quote;
    const TextBox2 = [
      {
        controlId: 'TextBox2',
        formLabel: 'If you have answered Yes to any of the questions above, please provide additional information',
        FormControl: [
          {
            type: 'textarea'
          }
        ]
      }
    ];
    const TextBox9 = [
      {
        controlId: 'TextBox9',
        formLabel: 'If you have answered Yes to any of the questions above, please provide additional information',
        FormControl: [
          {
            type: 'textarea'
          }
        ]
      }
    ];

    return (
      <Container className='Declarations' as='section'>
        <Row noGutters>
          <Col className='quoteFormWrap'>
            <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
            <div className='formDescription'>
              <div className='mobilePricing'>
                <h1 className='chevron'>{translateString(locale, 'Checkout')}</h1>
                <PolicyDetails />
              </div>
              <h3>{translateString(locale, 'Declarations')}</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              {this.state.isOtherPolicy && (
                <div className='declarationsFields'>
                  {!fetchingForms && checkoutData && <PrintFormGroup parent='declarations' data={checkoutData} handleChange={this.handleChange} />}
                  {(Answer1 === 'yes' ||
                    Answer2 === 'yes' ||
                    Answer3 === 'yes' ||
                    Answer4 === 'yes' ||
                    Answer5 === 'yes' ||
                    Answer6 === 'yes' ||
                    Answer7 === 'yes') && <PrintFormGroup parent='quote' data={TextBox2} handleChange={this.handleChange} />}
                  {(fetchingForms || !checkoutData) && <p>Loading policy data...</p>}
                </div>
              )}
              {this.state.isDPolicy && (
                <div className='declarationsFields'>
                  {!fetchingForms && checkoutDataD && <PrintFormGroup parent='declarations' data={checkoutDataD} handleChange={this.handleChange} />}
                  {(DecD1 === 'yes' ||
                    DecD2 === 'yes' ||
                    DecD3 === 'yes' ||
                    DecD4 === 'yes' ||
                    DecD5 === 'yes' ||
                    DecD6 === 'yes' ||
                    DecD7 === 'yes') && <PrintFormGroup parent='quote' data={TextBox9} handleChange={this.handleChange} />}
                  {(fetchingForms || !checkoutDataD) && <p>Loading policy data...</p>}
                </div>
              )}
              <div className='Line Green top'>
                {/* {translateString(
                  locale,
                  'The applicant declares that, to the best of his/her knowledge and belief, the statements set forth herein are true and correct. The applicant agrees that all personal information that provided to the Broker is complete and accurate. Furthermore if any significant change in the condition of the applicant is discovered between the date of the application and the effective date of the policy, which would render this application inaccurate or incomplete, notice of such change will be reported immediately in writing to the insurer.'
                )} */}
              </div>

              {!fetchingForms && checkoutData && (
                <FormBottomNav
                  disabled={this.validateForm()}
                  isLoading={this.state.isLoading}
                  prevText='Go Back'
                  prevPage={this.state.prevPage}
                  className=''
                  required={['All questions above']}
                />
              )}
              {/* <Link className="tempNext" to={this.state.nextPage}>
                Force Next Step
                </Link> */}
            </form>
          </Col>
          <Col lg={'auto'} className='sideBar'>
            <PolicyDetails />
          </Col>
        </Row>
        {/* <GeneralModal show={this.state.Modals[0].userFlag}>
          <p>
            Your application has been referred to an underwriter for review.
            Once the review has been completed we will contact you with the next
            steps.
          </p>
          <Button
            variant="primary"
            onClick={(e) => {
              this.handleModalClose("userFlag", e);
              window.location.href = 'https://www.advocisinsurance.ca/';
            }}
          >
            OK
          </Button>
        </GeneralModal> */}
      </Container>
    );
  }
}
export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchForms,
  saveForms,
  saveFormLines
})(Checkout);
