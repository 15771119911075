import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { getPricing, getLocale } from "../redux/reducers";
import { clearQuote } from "../redux/actions/quote";
import { fetchLocale } from "../redux/actions/locale";
import { translateString, translateJSXElement } from "../Assets/helpers/translations/translations";
import GeneralModal from "./GeneralModal";
import EpicAPI from "../Assets/api/epic";
import { ontarioStatHolidays } from "../Assets/data/statHolidays";
import header from "./header";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
  };
};

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { locale, fetchLocale } = this.props;

    if (!locale) fetchLocale();
  }
  checkAvailability = () => {
    const now = moment().utc().subtract(4, "hours").format("DDMMYYYY");
    // const { quote } = this.props;
    // console.log({ now, quote });
    // const nameTest = quote && quote.email === "test@test.test";
    const nowHour = moment().utc().subtract(4, "hours").format("HH");
    const hoursOfOperation = ["09", "10", "11", "12", "13", "14", "15", "16"];
    const statFormatted = ontarioStatHolidays.map((stat) => moment(stat).format("DDMMYYYY"));
    if (statFormatted.includes(now) || !hoursOfOperation.includes(nowHour)) {
      this.triggerWorkHoursModal();
    } else {
      window.location.href = translateString(this.props.locale, "https://advocisinsurance.ca/contact/");
    }
  };
  triggerWorkHoursModal = () => {
    this.setState({ showModal: true });
  };
  deletePolicy = async () => {
    // EpicAPI.
    const { clearQuote } = this.props;
    clearQuote();
  };

  render() {
    const { locale } = this.props;
    let year = moment().format("Y");
    return (
      <Container as="footer" fluid>
        <Row className="upperFooter">
          <Col sm className="logo">
            <div className="logo">{translateJSXElement(locale, "footer.logo")}</div>
          </Col>
          {/* <Col xs={"auto"}>
            <Row>
              <Col>
                <div className="logo_PMA">
                  <img src="/img/PMA_Logo.png" alt="Advocis Broker Services Logo - White" />
                </div>
              </Col>
            </Row>
          </Col> */}
        </Row>
        <Row className="lowerFooter">
          <Col>
            <p>
              {translateString(locale, "Copyright © Advocis Broker Services")} - {year}
              <span className="sep">|</span>
              {translateString(locale, "All Rights Reserved.")}
            </p>
            <br />
            {/* {locale === "EN" ? (<p>
              PMA Assurances Inc. is registered as a damage insurance broker firm by the Autorité des marchés financiers (AMF) under registration
              number 603119.{" "}
              <a
                target="_blank"
                href="https://lautorite.qc.ca/en/general-public/registers/register-of-firms-and-individuals-authorized-to-practice"
                rel="noreferrer">
                Click here
              </a>{" "}
              to view the AMF’s full register of firms and individuals authorized to practice. For information on how to file a complaint and a
              summary of the complaints processing policy of PMA Assurances Inc.{" "}
              <a target="_blank" href="https://pmaassurances.ca/fr/a-propos/politique-de-traitement-des-plaintes" rel="noreferrer">
                click here
              </a>
              .
            </p>) : (<></>)} */}
            <p className="Line Green top" style={{ paddingTop: 20 }}>
              <a href={translateString(locale, "https://advocisinsurance.ca/wordings/")} target="_blank" rel="noreferrer">
                {translateString(locale, "Policy Wordings")}  
              </a>
            </p>
          </Col>
          <Col xs="auto">
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/advocis" className="btn btn-primary social-icon">
                <i className="fa fa-linkedin" />
              </a>
              <a href="https://twitter.com/advocis" className="btn btn-primary social-icon">
                <i className="fa fa-twitter" />
              </a>
              <Button
                variant="primary"
                onClick={() => {
                  this.checkAvailability();
                }}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary footer__btn__broker">
                {translateString(locale, "Talk To A Broker")}
              </Button>
            </div>
          </Col>
        </Row>
        {process.env.REACT_APP_PRODUCTION === "false" && (
          <Row style={{ backgroundColor: "#00a664" }}>
            <Col>
              <p
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: 24,
                  fontWeight: "bold",
                }}>
                {process.env.REACT_APP_EPIC_DB} DATABASE
              </p>
            </Col>
          </Row>
        )}
        <GeneralModal show={this.state.showModal}>
          <h2>Talk to a Broker</h2>
          <p>We're sorry but no one is available to assist you at this time. Our hours of operation are </p>

          <p>Monday to Friday - 9am to 5pm EST</p>

          <p>We are closed for statutory holidays and on weekends</p>

          <p>
            If you would like to continue with your transaction with us without speaking with a broker, please click "Yes" otherwise please click "No"
            and your transaction will be cancelled
          </p>
          <div className="d-flex justify-content-between mt-2">
            <Button
              variant="alert"
              // as={"a"}
              // href="https://www.advocisinsurance.ca/"
              onClick={async () => {
                await this.deletePolicy();
                window.location.href = translateString(locale, "/");
              }}>
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}>
              Yes
            </Button>
          </div>
        </GeneralModal>
      </Container>
    );
  }
}

// export default Footer;

export default connect(mapStateToProps, {
  fetchLocale,
  clearQuote,
})(Footer);
