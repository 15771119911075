import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { connect } from 'react-redux';
import { fetchQuote, saveQuote } from '../redux/actions/quote';
import { fetchPricing } from '../redux/actions/pricing';
import { getQuote, getPricing, getLocale } from '../redux/reducers';
import { fetchLocale } from '../redux/actions/locale';
import { translateString, translateJSXElement } from '../Assets/helpers/translations/translations';
import taxRates from '../Assets/data/taxRates';

import { calculateDiscount, currencyFormat, loadPricing } from '../Assets/data/pricing';

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    pricing: getPricing(state)
  };
};
export class PolicyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponDiscount: 0,
      show: true,
      showTip: false,
      responsiveShow: false,
      broker_fee: 0,
      apa_fee: 0,
      tax: 0,
      total: 0,
      pre_tax_total: 0,
      coupon: { type: '', value: 0 },
      licensedDiscount: null,
      memberDiscount: null
    };
  }

  handleClose = () => {
    const show = false;
    this.setState({ show });
  };

  handleExpand = () => {
    if (this.state.responsiveShow === false) {
      this.setState({ responsiveShow: true });
    } else {
      this.setState({ responsiveShow: false });
    }
    // this.props.handleHeaderPadding(this.state.responsiveShow)
  };

  componentDidMount = () => {
    const { quote, fetchQuote, pricing, fetchPricing } = this.props;
    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      this.setState({ quote });
      if (quote.hasOwnProperty('coupon')) {
        this.setState({ coupon: { ...quote.coupon } });
      }
    }

    if (!pricing || _.isEmpty(pricing)) fetchPricing();
    else this.setState({ pricing });

    if (!document.querySelector('.headerClass').classList.contains('fixedPadding')) {
      document.querySelector('.headerClass').classList.add('fixedPadding');
    }
    this.calculateTotal();
    this.getTaxes();
  };

  componentDidUpdate(prevProps, prevState) {
    const { quote, pricing, locale, fetchLocale } = this.props;
    const { prices, coupon } = this.state;
    if (!locale) fetchLocale();
    if (!!quote && !!quote.coupon) {
      this.setCoupon();
    }
    if (!_.isEqual(quote, prevProps.quote)) {
      this.setState({ quote });
      // console.log("!_.isEqual(quote, prevProps.quote)", quote);
      this.setCoupon();
    }
    if (!_.isEqual(coupon, prevState.coupon)) {
      this.calculateTotal();
    }
    if (!_.isEqual(pricing, prevProps.pricing)) {
      this.setState({ pricing });
      this.calculateTotal();
    }

    if (!_.isEqual(this.state.quote, prevState.quote)) {
      this.getTaxes();
      this.getPricing();
      this.calculateTotal();
    }
    if ((!prices || _.isEmpty(prices)) && this.state.quote && pricing) {
      if (
        quote.lifeInsurance ||
        quote.iiroc ||
        quote.unlicensedPlanners ||
        // quote.licensedAdmin ||
        quote.directorsAndOfficers ||
        quote.cyberPrivacy
      ) {
        this.getPricing();
      }
    }

    if (!_.isEqual(prices, prevState.prices)) {
      this.calculateTotal();
    }
  }

  getPricing = () => {
    const { quote, pricing, tax } = this.state;
    let prices = loadPricing(quote, pricing, tax);
    this.setState({ prices });
  };
  getTaxes = () => {
    const { quote } = this.state;
    let tax;
    if (quote && quote.location) {
      // console.log(taxRates);
      // console.log(taxRates[quote.location]);
      tax = taxRates[quote.location];
    }
    this.setState({ tax });
  };
  setCoupon = () => {
    const { quote } = this.props;
    if (!!quote.coupon && !_.isEqual(quote.coupon, this.state.coupon)) {
      this.setState({ coupon: quote.coupon });
    }
  };
  calculateTotal = () => {
    const { prices, tax } = this.state;
    let total = 0;
    let pre_tax_total = 0;
    let taxable_total = 0;
    let zurich_total = 0;
    let dfcf_total = 0;
    // console.log(prices);
    _.each(prices, (price) => {
      total += price.amount;
      if (price.amount > 0) {
        if (price.label.toLowerCase().indexOf('cyber') < 0) {
          zurich_total += price.zurich;
          dfcf_total += price.amount - price.zurich;
        }
        taxable_total += price.zurich;
      }
    });

    let {
      totalDiscount,
      couponDiscount,
      memberDiscount,
      licensedDiscount,
      couponPct,
      memberPct,
      licensedPct,
      couponZurichDiscount,
      licensedZurichPct
    } = calculateDiscount(this.props.quote, zurich_total, dfcf_total);

    taxable_total = taxable_total - couponZurichDiscount - licensedZurichPct - memberDiscount;

    this.setState({ couponDiscount, memberDiscount, licensedDiscount, couponPct, memberPct, licensedPct });
    total = total - totalDiscount;

    pre_tax_total = total;

    let taxes = 0;
    _.each(tax, (t) => {
      taxes += taxable_total * (t.tax / 100);
    });
    total += taxes;
    this.setState({ total, pre_tax_total, taxable_total });
  };

  render() {
    const { quote, hideEdit, locale } = this.props;

    const { prices, total, licensedPct, memberPct } = this.state;
    // console.log(prices);
    if (!quote) return <></>;
    return (
      <div>
        <div className={this.state.responsiveShow ? 'PolicyDetails active' : 'PolicyDetails'}>
          <div className='PolicyDetails__mobileHeader' onClick={this.handleExpand}>
            <div className='cost'>{translateString(locale, 'Policy Details')}</div>
            <div className='price'></div>
          </div>
          <div className='PolicyDetails__Wrapper'>
            <div className='PolicyDetails__header'>
              <h2>{translateString(locale, 'Policy Details')}</h2>
              {/* {!hideEdit && (
                <Link to="/quote/review" className="btn btn-tertiary edit">
                Edit
              </Link>
              )} */}
            </div>
            <div className='PolicyDetails__items'>
              <div className='item number'>
                <div className='label'>
                  {translateString(locale, 'Coverage Start Date:')} {quote.startDate}
                </div>
              </div>
              {prices &&
                prices.map((price, i) => (
                  <div className='item number' key={i}>
                    <div className='label'>{translateString(locale, price.label)}</div>
                    <div className='value'>
                      {currencyFormat(price.limit, locale, 0)} {translateString(locale, 'Limit')}
                    </div>
                  </div>
                ))}
              {this.state.couponDiscount > 0 ? (
                <div className='item number alertText'>
                  <div className='label'>
                    {translateString(locale, 'Coupon Code:')} {this.state.coupon.type}
                  </div>
                  <div className='value'>-{currencyFormat(this.state.couponDiscount, locale)}</div>
                </div>
              ) : null}
              {!!this.state.memberDiscount && (
                <div className='item number alertText'>
                  <div className='label'>
                    {translateString(locale, 'Advocis Member Discount')} ({memberPct}%)
                  </div>
                  <div className='value'>-{currencyFormat(this.state.memberDiscount, locale)}</div>
                </div>
              )}
              {!!this.state.licensedDiscount && (
                <div className='item number alertText'>
                  <div className='label'>
                    {translateString(locale, 'New Advisor Discount')} ({licensedPct}%)
                  </div>
                  <div className='value'>-{currencyFormat(this.state.licensedDiscount, locale)}</div>
                </div>
              )}
            </div>
            <div className='PolicyDetails__footer'>
              <div className='total number'>
                <div className='label'>{translateString(locale, 'Total Premium Due')}</div>
                {total && <div className='value'>{currencyFormat(total, locale)}</div>}
              </div>
              {/* {!hideEdit && (
                <div className="message successText">Something not right? Use the edit button above to go back and change your coverage.</div>
              )} */}
            </div>
          </div>
        </div>
        {this.state.responsiveShow ? <div className='modal-backdrop'></div> : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchPricing
})(PolicyDetails);
