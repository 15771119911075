const coverages_data = [
  {
    cyberPrivacy: [
      {
        exclusive: true,
        details: [
          {
            controlId: "cyberPrivacy",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Cyber/Security and Privacy Liability",
                subLabel: "Please note this coverage is not automatically included with your E&O policy and must be purchased separately",
                default: false,
                toolTip: [
                  {
                    text: "Cyber Suite Insurance relating to Privacy, Confidentiality, and Security Liability; Privacy Regulation Defense, Awards and Fines; Customer Care and Reputational Expenses.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                    learnMore: "https://advocisinsurance.ca/coverages-a-b-c-d/#P",
                    learnMoreText: "Learn More"

                  },
                ],
                dependencies: [
                  {
                    cyberPrivacyPrice: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "cyberPrivacyPrice",
                            FormControl: [
                              {
                                type: "buttons",
                                buttons: [
                                  {
                                    options: [25000, 50000, 100000, 250000],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    lifeInsurance: [
      {
        exclusive: true,
        details: [
          {
            controlId: "lifeInsurance",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Life Insurance / Mutual Funds",
                // formLabel: "Life Insurance",
                subLabel: "",
                default: false,
                toolTip: [
                  {
                    text: "Coverage for activities relating to the conduct of the business of a licensed life agent or licensed accident and/or sickness agent or a representative registered with the Mutual Fund Dealers Association of Canada to sell Mutual Funds, Life Insurance, Segregated Funds, Accident & Sickness, Term Deposits, Group Benefits, GIC’s and more.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                    learnMore: "https://advocisinsurance.ca/coverages-a-b-c-d/#A",
                    learnMoreText: "Learn More"
                  },
                ],
                dependencies: [
                  {
                    lifeInsurancePrice: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "lifeInsurancePrice",
                            FormControl: [
                              {
                                type: "buttons",
                                buttons: [
                                  {
                                    default: 1000000,
                                    options: [1000000, 2000000, 3000000, 4000000, 5000000, 10000000],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    iiroc: [
      {
        exclusive: true,
        details: [
          {
            controlId: "iiroc",
            FormControl: [
              {
                type: "toggle",
                formLabel: "IIROC Licensees (Securities/Mutual Funds)",
                subLabel: "",
                default: false,
                toolTip: [
                  {
                    text: "Coverage for activities related to a securities registrant registered with the Investment Industry Regulatory Organization of Canada, including Securities, Income Tax Preparation, Executors, Notary Public, Commissioner of Oaths, Fee for Service Planning, Financial Divorce Specialists and more.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                    learnMore: "https://advocisinsurance.ca/coverages-a-b-c-d/#B",
                    learnMoreText: "Learn More"
                  },
                ],
                dependencies: [
                  {
                    iirocPrice: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "iirocPrice",
                            FormControl: [
                              {
                                type: "buttons",
                                buttons: [
                                  {
                                    default: 1000000,
                                    options: [1000000, 2000000, 3000000, 4000000, 5000000, 10000000],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    unlicensedPlanners: [
      {
        exclusive: true,
        details: [
          {
            controlId: "unlicensedPlanners",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Unlicensed Planners/Fee for Service",
                subLabel: "",
                default: false,
                toolTip: [
                  {
                    text: "Activities provided for a fee relating to financial advice and for which a license is not required. Activities relating to and including sales management and supervisory activities over individual advisors and planners in reference to the above.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                    learnMore: "https://advocisinsurance.ca/coverages-a-b-c-d/#C",
                    learnMoreText: "Learn More"
                  },
                ],
                dependencies: [
                  {
                    unlicensedPlannersPrice: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "unlicensedPlannersPrice",
                            FormControl: [
                              {
                                type: "buttons",
                                buttons: [
                                  {
                                    default: 1000000,
                                    options: [1000000, 2000000, 3000000, 4000000, 5000000],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    unlicensedPlannersCheckbox: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "unlicensedPlannersCheckbox",
                            description: "You must hold a valid designation. Please select from the list below.",
                            FormControl: [
                              {
                                type: "checkbox",
                                checkbox: [
                                  {
                                    options: [
                                      "Certified Financial Planner, CFP ®",
                                      "Chartered Life Underwriter, CLU ®",
                                      "Certified Health Insurance Specialist, CHS™",
                                      "Chartered Financial Consultant, CH.F.C.",
                                      "Trust and Estate Practitioners, TEP",
                                      "Canadian Investment Management, CIM",
                                      "Fellow of the Canadian Securities Institute, FCSI®",
                                      "Registered Financial Planner, R.F.P.",
                                      "Financial Divorce Specialist, FDS",
                                      "Family Enterprise Advisor, FEA",
                                      "Personal Financial Planner, PFP ®",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    otherDesignations: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "otherDesignations",
                            FormControl: [
                              {
                                type: "toggle",
                                formLabel: "Other Designations",
                                default: false,
                                dependencies: [
                                  {
                                    TextBox3: [
                                      {
                                        details: [
                                          {
                                            condition: true,
                                            controlId: "TextBox3",
                                            FormControl: [
                                              {
                                                autoFocus: false,
                                                type: "text",
                                                placeholder: "",
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    licensedAdmin: [
      {
        exclusive: true,
        details: [
          {
            controlId: "licensedAdmin",
            FormControl: [
              {
                type: "toggle",
                formLabel: "Licensed Administrative Assistants",
                subLabel: "",
                default: false,
                toolTip: [
                  {
                    text: "Coverage for those activities, relating to a licensed administrative assistant: Life Insurance, Annuities, Accident & Sickness, hospitalization or medical insurance policies, investments otherwise insured under the policy.",
                    origin: "horizontal",
                    vertical: "top",
                    horizontal: "left",
                    learnMore: "https://advocisinsurance.ca/coverages-a-b-c-d/#D",
                    learnMoreText: "Learn More"
                  },
                ],
                dependencies: [
                  {
                    licensedAdminPrice: [
                      {
                        details: [
                          {
                            condition: true,
                            controlId: "licensedAdminPrice",
                            FormControl: [
                              {
                                type: "buttons",
                                buttons: [
                                  {
                                    options: [1000000, 2000000],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    /*directorsAndOfficers: [{
        exclusive: true,
        details: [{
          controlId: "directorsAndOfficers",
          FormControl: [{
            type: "toggle",
            formLabel: "Directors and Officers",
            subLabel: "",
            default: false,
            toolTip: [
              {
                text: "",
                origin: "horizontal",
                vertical: "top",
                horizontal: "left",
              }
            ],
            dependencies: [{
              directorsAndOfficersPrice: [{
                details: [{
                  condition: true,
                  controlId: "directorsAndOfficersPrice",
                  FormControl: [{
                    type: "buttons",
                    buttons: [{
                      options: [500000, 1000000]
                    }],
                  }]
                }]
              }]
            }]
          }]
        }]
      }]*/
    // cyberPrivacy: [
    //   {
    //     check: true,
    //     exclusive: false,
    //     details: [
    //       {
    //         controlId: "cyber",
    //         formLabel: "Cyber/Privacy and Network",
    //         FormControl: [
    //           {
    //             type: "checkbox",
    //             value: false,
    //             toolTip: [
    //               {
    //                 text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor",
    //                 origin: "horizontal",
    //                 vertical: "top",
    //                 horizontal: "left",
    //               }
    //             ],
    //             dependencies: [
    //               {
    //                 buttons: [
    //                   {
    //                     value: 25000,
    //                     options: [25000, 50000, 100000, 250000]
    //                   }
    //                 ],
    //               }
    //             ]
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ]
  },
];
export default coverages_data;
