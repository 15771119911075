import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import FormBottomNav from "../../components/FormBottomNav";
import FormTopNav from "../../components/FormTopNav";
import GeneralModal from "../../components/GeneralModal";
import steps from "../../Assets/data/checkoutStepsData";
import PolicyDetails from "../../components/PolicyDetails";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchQuote, saveQuote, fetchQuoteFromFirst } from "../../redux/actions/quote";
import EpicAPI from "../../Assets/api/epic";
import { fetchForms, saveFormLines, saveForms } from "../../redux/actions/forms";
import FirstAPI from "../../Assets/api/first";

import { getFormLines, getForms, getQuote, getPricing, getLocale } from "../../redux/reducers";
import { fetchLocale } from "../../redux/actions/locale";
import { translateString, translateJSXElement } from "../../Assets/helpers/translations/translations";

export const mapStateToProps = (state, ownProps) => {
  return {
    locale: getLocale(state),
    quote: getQuote(state),
    forms: getForms(state),
    lines: getFormLines(state),
    pricing: getPricing(state),
  };
};
export class CheckoutPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 5,
      steps: steps,
      noYearly: false,
      timeMethod: "",
      firstQuoteNumber: "",
      loadingPayment: true,
      paymentPage: null,
      isLoading: false,
      addedAcordPayTags: false,
      iframeRetries: 0,
      testUser: true,
      title: "Payment",
      copy: "",
      prevPage: "/checkout/payment",
      nextPage: "/checkout/processing",
      Modals: [
        {
          isQuebec: false,
        },
      ],
    };
    this.resultInt = null;
    this.intv = null;
  }

  handleModalShow = (key, e) => {
    // e.preventDefault();
    //console.log(e);
    let Modals = { ...this.state.Modals };
    Modals[0][key] = true;
    this.setState({
      Modals,
    });
  };

  handleModalClose = (key) => {
    let Modals = { ...this.state.Modals };
    Modals[0][key] = false;
    this.setState({
      Modals,
    });
  };

  componentDidMount() {
    const { quote, fetchQuote, pricing, fetchLocale, locale } = this.props;
    const { paymentPage } = this.state;
    if (!locale) fetchLocale();
    if (!quote || _.isEmpty(quote)) {
      fetchQuote();
    } else {
      if (!paymentPage && quote && quote.firstQuoteNumber) {
        this.getPaymentPage(quote);
      } else {
        this.setState({ loadingQuote: false });
      }
      this.setQuoteState(quote);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quote, lines, pricing, locale } = this.props;
    const { paymentPage } = this.state;
    if (!_.isEqual(lines, prevProps.lines)) this.setState(lines);
    if (!_.isEqual(quote, prevProps.quote)) {
      if (!paymentPage && quote && quote.firstQuoteNumber) this.getPaymentPage(quote);
      this.setQuoteState(quote);
    }
    if (quote && quote.timeMethod && quote.timeMethod !== this.state.timeMethod) {
      this.setState({ timeMethod: quote.timeMethod });
    }
  }

  setQuoteState(quote) {
    this.setState({
      ...quote,
    });
  }

  getPaymentPage = async (quote) => {
    const { locale } = this.props;
    if (
      quote.location
      // && quote.location !== "QC"
    ) {
      let paymentPage = null;
      if (quote.timeMethod === "Yearly" && !this.state.noYearly) {
        // this.setState({ paymentPage: 'yearly', loadingPayment: true });
        this.intv = setInterval(() => {
          this.openCreditPayment();
        }, 100);
      } else if (quote.timeMethod === "Monthly") {
        this.setState({
          copy: (
            <div>
              <p>{translateString(locale, "Loading payment page")}...</p>
            </div>
          ),
        });
        await FirstAPI.getFinancingIframe(quote)
          .then((data) => {
            // console.log({ data })
            if (data.error) {
            }
            if (data.url) {
              paymentPage = data.url;
              const copy = ``;
              this.setState({ copy });
              this.setState({ paymentPage, loadingPayment: false });
              this.watch();
            } else {
              // console.log('no URL passed back')
              this.retryIframe(quote);
            }
          })
          .catch((e) => {
            // console.log(e);
            // console.log('failed to load payment page, trying again.')
            this.retryIframe(quote);
          });
      }
    }
  };

  retryIframe = async (quote) => {
    let { paymentPage, locale } = this.state;
    // console.log('retrying getting iframe url');
    await FirstAPI.getFinancingIframe(quote)
      .then((data) => {
        if (data.error) {
          // console.log(data.error);
        }
        if (data.url) {
          // console.log('retry, ', { url: data.url });
          paymentPage = data.url;
          this.setState({ paymentPage, loadingPayment: false });
          const copy = ``;
          this.setState({ copy });
          this.watch();
        } else {
          // console.log('retry, failed to get URL');
          this.setState({ iframeRetries: this.state.iframeRetries + 1 });
          if (this.state.iframeRetries < 5) {
            const copy = (
              <div>
                <p>
                  {translateString(locale, "Loading payment page")}... ({this.state.iframeRetries} {translateString(locale, "attempt")}
                  {this.state.iframeRetries > 1 ? "s" : ""})`
                </p>
              </div>
            );
            this.setState({ copy });
            this.retryIframe(quote);
          } else {
            const copy = (
              <div>
                <p>
                  {" "}
                  {translateString(locale, "Failed to load payment page after")} {this.state.iframeRetries} {translateString(locale, "attempts")}.
                </p>
                <Button variant="primary" onClick={() => window.location.reload(false)}>
                  {translateString(locale, "Retry")}
                </Button>
              </div>
            );
            this.setState({ copy });
          }
        }
      })
      .catch((e) => {
        this.setState({ iframeRetries: this.state.iframeRetries + 1 });
        if (this.state.iframeRetries < 5) {
          const copy = (
            <div>
              <p>
                {translateString(locale, "Loading payment page")}... ({this.state.iframeRetries} {translateString(locale, "attempt")}
                {this.state.iframeRetries > 1 ? "s" : ""})`
              </p>
            </div>
          );
          this.setState({ copy });
          this.retryIframe(quote);
        } else {
          const copy = (
            <div>
              <p>
                {" "}
                {translateString(locale, "Failed to load payment page after")} {this.state.iframeRetries} {translateString(locale, "attempts")}.
              </p>
              <Button variant="primary" onClick={() => window.location.reload(false)}>
                {translateString(locale, "Retry")}
              </Button>
            </div>
          );
          this.setState({ copy });
          console.log(e);
        }
      });
  };

  openCreditPayment = () => {
    if (window.acordEmbed) {
      const { quote, locale } = this.props;
      let first_name = "";
      let last_name = "";
      if (quote && quote.FirstName && quote.Last) {
        first_name = quote.FirstName;
        last_name = quote.Last;
      }
      let policies = "";
      if (quote && quote.policies) {
        policies = quote.policies[0].id + "";
        quote.policies.forEach((policy, i) => {
          if (i > 0) {
            policies += " & " + policy.id;
          }
        });
      }
      if (quote && quote.total && quote.total > 0 && policies !== "") {
        let total = quote.total;

        const paymentObject = {
          amount: total + "",
          policyId: policies,
          firstName: first_name,
          lastName: last_name,
          bFirstName: first_name,
          bLastName: last_name,
          email: quote.email,
          language: locale.toLowerCase(),
        };
        window.acordEmbed.open(paymentObject, this.paymentCallback);
        clearInterval(this.intv);
        this.setState({ loadingPayment: false });
      }
    }
  };

  paymentCallback = async (embedResult) => {
    const { locale } = this.props;
    if (embedResult && embedResult.status === "SUCCESS") {
      // this.setState({ currentStep: 6 });
      // this.setState({ title: "Payment Approved" });
      // const copy = `Transaction Approved!\n
      // Transaction ID: ${embedResult.transactionId}\n
      // Thank for for purchasing your insurange with Advocis Broker Services. We are reviewing your application.
      // `;
      // this.setState({ copy });
      // const { lines } = this.props;
      // this.props.history.push(this.state.nextPage)

      const { forms, lines } = this.props;
      const _forms = { ...forms };
      if (_forms && _forms.screens && _forms.screens[6] && _forms.screens[6].fields) {
        const paidIndex = _.findIndex(_forms.screens[6].fields, { FieldName: "PaidFlag" });
        _forms.screens[6].fields[paidIndex].FieldValue = "Credit Card";
      }
      await EpicAPI.updateCustomForm(_forms, lines, true)
        .then((res) => {
          this.props.history.push(this.state.nextPage);
        })
        .catch((e) => {
          this.setState({ title: "Payment Error" });
          const copy =
            embedResult && embedResult.message ? (
              <div>
                <p>{embedResult.message}</p>
                <Button variant="primary" onClick={() => this.props.history.push(this.state.prevPage)}>
                  {translateString(locale, "Retry")}
                </Button>
              </div>
            ) : (
              <div>
                <p>{translateString(locale, "Transaction Error, Please try again.")}</p>
                <Button variant="primary" onClick={() => this.props.history.push(this.state.prevPage)}>
                  {translateString(locale, "Retry")}
                </Button>
              </div>
            );
          this.setState({ copy });
        });
    } else if (embedResult && embedResult.status === "CANCEL") {
      this.setState({ title: "Payment Canceled" });
      const copy = (
        <div>
          <p>{translateString(locale, "Transaction Canceled, Please try again.")}</p>
          <Button variant="primary" onClick={() => this.props.history.push(this.state.prevPage)}>
            {translateString(locale, "Retry")}
          </Button>
        </div>
      );
      this.setState({ copy });
    } else {
      this.setState({ title: "Payment Error" });
      const copy =
        embedResult && embedResult.message ? (
          <div>
            <p>{embedResult.message}</p>
            <Button variant="primary" onClick={() => this.props.history.push(this.state.prevPage)}>
              {translateString(locale, "Retry")}
            </Button>
          </div>
        ) : (
          <div>
            <p>{translateString(locale, "Transaction Error, Please try again.")}</p>
            <Button variant="primary" onClick={() => this.props.history.push(this.state.prevPage)}>
              {translateString(locale, "Retry")}
            </Button>
          </div>
        );
      this.setState({ copy });
    }
  };

  watch = () => {
    const { lines } = this.props;
    const { loadingPayment, paymentPage } = this.state;
    this.resultInt = setInterval(() => {
      _.each(lines, (line) => {
        EpicAPI.getCustomForm(line).then((form) => {
          if (form && form.screens && form.screens[6] && form.screens[6].fields) {
            let paid_field = _.find(form.screens[6].fields, (field) => field.FieldName === "PaidFlag");
            if (paid_field.FieldValue !== "") {
              clearInterval(this.resultInt);
              this.props.history.push(this.state.nextPage);
            }
          }
        });
      });
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.resultInt);
  }

  validateForm() {
    return true;
  }

  handleButtonChange = (id, val) => {
    // console.log(this.state)
    this.setState({
      [id]: val,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.history.push(this.state.nextPage);
  };

  render() {
    const { quote, locale } = this.props;
    const { loadingPayment, paymentPage, title, copy, noYearly } = this.state;
    return (
      <Container className="Payment">
        {quote ? (
          // quote.location !== "QC" && quote.location
          <Row noGutters>
            <Col className="quoteFormWrap">
              <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
              <div className="formDescription">
                <div className="mobilePricing">
                  <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                  <PolicyDetails hideEdit={true} />
                </div>
              </div>
              {noYearly && quote && quote.timeMethod && quote.timeMethod === "Yearly" ? (
                <div className="Line Gray top bottom">
                  {/* <p>Loading Payment Page, please wait...</p> */}
                  {/* <p>Your policy will be reviewed by our brokerage team and you will be contacted to finalize your coverage within 72 hours. For any inquiries on this policy please contact us at <a href="tel:1-877-646-9888">1-877-646-9888</a></p>
                  <p>Client ID: {quote && quote.clientID ? quote.clientID : ""}</p>
                  <p>Client Email: {quote && quote.email ? quote.email : ""}</p>
                  <p>Policy ID{(quote && quote.activePolicies && quote.activePolicies.length > 0) ? 's' : ''}: {quote && quote.activePolicies ? quote.activePolicies.join(', ') : ""}</p> */}
                  <h3>{translateString(locale, "Congratulations!")}</h3>
                  <p>
                    {translateString(
                      locale,
                      "Your policy application has been received by our brokerage team. Our team will review your information."
                    )}{" "}
                    <strong>{translateString(locale, "A payment link will follow shortly.")}</strong>
                  </p>
                  {/* <FormBottomNav
                    disabled={true}
                    isLoading={this.state.isLoading}
                    prevText="Start Over"
                    prevPage={'/checkout'}
                    className="Line Green top"
                  /> */}
                </div>
              ) : (
                <div>
                  <h3>{translateString(locale, title)}</h3>
                  {copy !== "" ? <>{copy}</> : ""}
                </div>
              )}
              {!loadingPayment && paymentPage && paymentPage !== "error" && paymentPage !== "yearly" && (
                <iframe src={paymentPage} height="600" width="100%" frameBorder={0} title="payment-iframe" />
              )}
              {!noYearly && loadingPayment && paymentPage && paymentPage === "yearly" && (
                <div className="Line Gray top bottom">
                  <p>{translateString(locale, "Opening Credit Card Payment Form")}</p>
                </div>
              )}
              {!loadingPayment && paymentPage === "error" && (
                <form onSubmit={this.handleSubmit}>
                  <div className="Line Gray top">
                    <div className="paymentMethod">
                      {translateString(locale, "We've run into an error loading the payment page, please refresh the page and try again.")}
                    </div>
                  </div>
                  <div className="tempNext" onClick={() => window.location.reload()}>
                    {translateString(locale, "Reload")}
                  </div>
                </form>
              )}
            </Col>
            <Col lg={"auto"} className="sideBar">
              <PolicyDetails hideEdit={true} />
            </Col>
          </Row>
        ) : (
          <Row noGutters>
            <Col className="quoteFormWrap">
              <FormTopNav steps={this.state.steps} currentStep={this.state.currentStep} />
              <div className="formDescription">
                <div className="mobilePricing">
                  <h1 className="chevron">{translateString(locale, "Checkout")}</h1>
                  <PolicyDetails hideEdit={true} />
                </div>
                <h3>{translateString(locale, title)}</h3>
                {copy !== "" ? <>{copy}</> : ""}
              </div>
              <div className="Line Gray top bottom">
                {/* <p>Loading Payment Page, please wait...</p> */}
                <p>
                  {translateString(
                    locale,
                    "Your policy will be reviewed by our brokerage team and you will be contacted to finalize your coverage within 72 hours. For any inquiries on this policy please contact us at "
                  )}
                  <a href="tel:1-877-646-9888">1-877-646-9888</a>
                </p>
                <p>
                  {translateString(locale, "Client ID")}: {quote && quote.clientID ? quote.clientID : ""}
                </p>
                <p>
                  {translateString(locale, "Client Email")}: {quote && quote.email ? quote.email : ""}
                </p>
                <p>
                  {translateString(locale, "Policy ID")}
                  {quote && quote.activePolicies && quote.activePolicies.length > 0 ? "s" : ""}:{" "}
                  {quote && quote.activePolicies ? quote.activePolicies.join(", ") : ""}
                </p>
                {/* <FormBottomNav
                    disabled={true}
                    isLoading={this.state.isLoading}
                    prevText="Start Over"
                    prevPage={'/checkout'}
                    className="Line Green top"
                  /> */}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default connect(mapStateToProps, {
  fetchLocale,
  fetchQuote,
  saveQuote,
  fetchQuoteFromFirst,
  fetchForms,
  saveForms,
  saveFormLines,
})(CheckoutPayment);
