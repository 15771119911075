import payment from '../../../pages/checkout/payment';

export function translateString(locale = 'EN', needle) {
  // console.log({ locale });
  if (locale === 'EN') {
    return needle;
  }

  const haystack = [
    {
      en: 'English Needle',
      fr: 'French Needle'
    },
    {
      en: 'Completed:',
      fr: 'Finalisé :'
    },
    {
      en: 'Master',
      fr: 'Contrat-cadre'
    },
    {
      en: 'Your ABS Policy',
      fr: "Votre police d'assurance ABS"
    },
    {
      en: 'Certificate for Policy',
      fr: "Certificat joint à la police d'assurance"
    },
    {
      en: 'Security &amp; Privacy Liability',
      fr: 'Responsabilité en matière de sécurité et de confidentialité'
    },
    {
      en: 'E &amp; O - Coverage A',
      fr: 'E&amp;O - Garantie A'
    },
    {
      en: 'E &amp; O - Coverage B',
      fr: 'E&amp;O - Garantie B'
    },
    {
      en: 'E &amp; O - Coverage C',
      fr: 'E&amp;O - Garantie C'
    },
    {
      en: 'E &amp; O - Coverage D',
      fr: 'E&amp;O - Garantie D'
    },
    {
      en: '/',
      fr: '/'
    },
    {
      en: 'Fee for Service',
      fr: 'Rémunération à l’acte'
    },
    {
      en: 'Financial Divorce Specialist',
      fr: 'Spécialiste financier en divorce'
    },
    {
      en: 'Financial Divorce Specialists',
      fr: 'Spécialistes financiers en divorce'
    },
    {
      en: 'Personal Income Tax Preparation',
      fr: 'Préparation de déclarations de revenus personnels'
    },
    {
      en: 'Per Claim Errors & Omissions Liability - Claims Made',
      fr: 'Responsabilité professionnelle, limite par réclamation - selon les réclamations présentées'
    },
    {
      en: 'Included',
      fr: 'Inclus'
    },
    {
      en: 'Signed on',
      fr: 'Fait le'
    },
    {
      en: 'on behalf of insurers by:',
      fr: 'pour le compte des assureurs par'
    },
    {
      en: 'Disciplinary Defence Costs Extension Shared Policy Aggregate for all Certificates Holders',
      fr: 'Extension de la garantie aux frais de défense de nature disciplinaire - montant global de la garantie partagée entre tous les titulaires de certificats'
    },
    {
      en: 'As above and as applicable',
      fr: 'Comme indiqué ci-dessus et le cas échéant'
    },
    {
      en: 'Disciplinary Defence Costs Extension Per Certificate, Per Claim, Per Policy Period',
      fr: 'Extension de la garantie aux frais de défense de nature disciplinaire par certificat, par réclamation, par période d’assurance'
    },
    {
      en: 'Fraudulent Acts Endorsement for provinces where it is a regulatory requirement',
      fr: 'Avenant concernant les actes frauduleux pour les provinces où il s’agit d’une exigence réglementaire'
    },
    {
      en: '$0 each Claim',
      fr: '0 $ par réclamation'
    },
    {
      en: '$5,000 each Claim',
      fr: '5 000 $ par réclamation'
    },
    {
      en: '$1,000 each Claim',
      fr: '1 000 $ par réclamation'
    },
    {
      en: 'Liability Limit',
      fr: 'Limite de responsabilité'
    },
    {
      en: 'Coverage Description',
      fr: 'Description de la garantie'
    },
    {
      en: 'Deductible',
      fr: 'Franchise'
    },
    {
      en: 'Retroactive Date',
      fr: 'Date limite de rétroactivité'
    },
    {
      en: 'Not applicable',
      fr: 'Sans objet'
    },
    {
      en: 'Vicarious Liability Provision',
      fr: 'CLAUSE DE RESPONSABILITÉ DU FAIT D’AUTRUI'
    },
    {
      en: 'Vicarious liability coverage is provided to the above-listed Firm through which the Insured is or was doing business or is or was employed, provided such Insured is required to provide vicarious liability coverage for such Firm by reason of liability imposed by law.',
      fr: 'La garantie pour la responsabilité du fait d’autrui est fournie au cabinet susmentionné par l’intermédiaire duquel l’assuré fait ou faisait des affaires ou encore, exerce ou exerçait un emploi, pourvu que l’assuré soit lui-même tenu de fournir une garantie en matière de responsabilité du fait d’autrui audit cabinet et ce, en raison de la responsabilité qui lui incombe légalement.'
    },
    {
      en: 'Prior Knowledge Exclusion',
      fr: 'EXCLUSION DES FAITS ANTÉRIEUREMENT CONNUS '
    },
    {
      en: 'This policy does not apply to any Claim or circumstances which may reasonably result in a Claim, whether reported in the application or not, known to any Insured before the effective date of this policy. When this policy replaces, without interruption, a policy previously issued by the Insurer, this exclusion applies only to Claims previously known to an Insured before the inception date of the first policy issued by the Insurer.',
      fr: 'La présente police ne s’applique pas à toute réclamation ou à des circonstances qui pourraient raisonnablement se solder par une réclamation, qu’elle soit déclarée ou non dans la demande, connue de tout assuré avant la date d’entrée en vigueur de la présente police.'
    },
    {
      en: 'All other terms, limits, exclusions and conditions as per master policy',
      fr: 'TOUTES LES AUTRES MODALITÉS, LIMITES, EXCLUSIONS ET CONDITIONS SONT ÉNONCÉES DANS LE CONTRAT-CADRE'
    },
    {
      en: 'underwritten by Zurich Insurance Company Ltd.',
      fr: 'ÉMIS PAR ZURICH COMPAGNIE D’ASSURANCES SA'
    },
    {
      en: 'Zurich insurance Company',
      fr: 'Zurich Compagnie d’Assurances'
    },
    {
      en: 'Executors',
      fr: 'Exécuteurs'
    },
    {
      en: 'Unlicensed Planners - Fee for Service',
      fr: 'Planificateurs non agréés - rémunération à l’acte'
    },
    {
      en: 'Notaries Public',
      fr: 'Notaires publics'
    },
    {
      en: 'Licensed Administrative Assistant',
      fr: 'Adjoints administratifs agréés'
    },
    {
      en: 'Each Claim',
      fr: 'Par réclamation'
    },
    {
      en: 'Aggregate each Named Insured per Policy Term',
      fr: 'Capital assuré global pour chaque assuré désigné par durée du contrat'
    },
    {
      en: 'Reward Payments',
      fr: 'Paiements compensatoires'
    },
    {
      en: 'NIL',
      fr: 'NIL'
    },
    {
      en: 'Product',
      fr: 'Produit'
    },
    {
      en: 'Shared Policy Aggregate for all Named Insureds',
      fr: 'Capital assuré global pour tous les assurés désignés'
    },
    {
      en: 'Policy Aggregate for all B & C certificate holders in the plan',
      fr: 'Capital assuré global pour tous les titulaires de certificats au titre des garanties B et C du régime'
    },
    {
      en: 'IN WITNESS WHEREOF, Zurich Insurance Company Ltd has duly authorized Advocis Broker Services Inc. to execute and issue this Certificate of Insurance.',
      fr: 'EN FOI DE QUOI, Zurich Compagnie d’Assurances SA a dûment autorisé Advocis Services Aux Courtiers à signer et à émettre le présent certificat d’assurance.'
    },
    {
      en: 'Advocis Broker Services Inc.',
      fr: 'Services aux courtiers Advocis, Inc.'
    },
    {
      en: '10 Lower Spadina Ave., Suite 700',
      fr: '10, avenue Lower Spadina, bureau 700'
    },
    {
      en: 'Toronto, ON M5V 2Z2',
      fr: 'Toronto (Ontario)  M5V 2Z2'
    },
    {
      en: 'For purposes of the Insurance Companies Act (Canada), this document was issued in the course of Zurich Insurance Company Ltd’s insurance business in Canada.',
      fr: 'Aux fins de la Loi sur les sociétés d’assurances (Canada), le présent document a été délivré dans le cadre des activités d’assurance au Canada de Zurich Compagnie d’Assurances SA.'
    },
    {
      en: 'Insurer',
      fr: 'Assureur'
    },
    {
      en: 'Certificate Number',
      fr: 'Numéro de certificat'
    },
    {
      en: 'Insured',
      fr: 'Assuré'
    },
    {
      en: 'Disability & Critical Illness',
      fr: 'Invalidité et maladies graves'
    },
    {
      en: 'Address',
      fr: 'Adresse'
    },
    {
      en: 'Firm Name',
      fr: 'Raison sociale'
    },
    {
      en: 'Effective Date',
      fr: 'Date d’entrée en vigueur'
    },
    {
      en: 'Expiry Date',
      fr: 'Date d’expiration'
    },
    {
      en: 'Provinces licences held',
      fr: 'Permis provinciaux détenus'
    },
    {
      en: 'Load Sample',
      fr: 'Chargement d’un échantillon'
    },
    {
      en: 'Policy Not Found',
      fr: 'Police introuvable'
    },
    {
      en: 'This Certificate of Insurance is evidence of insurance described under',
      fr: 'Le présent certificat d’assurance constitue un titre de garantie de l’assurance décrite dans '
    },
    {
      en: 'Policy No.',
      fr: 'n.'
    },
    {
      en: 'Advocis Protective Association E&O Plan',
      fr: 'Régime d’assurance responsabilité professionnelle d’Advocis Protective Association'
    },
    {
      en: 'Certificate of Insurance - Individual E&O Liability',
      fr: 'Certificat d’assurance individuelle – Assurance responsabilité professionnelle'
    },
    {
      en: 'Security & Privacy Liability',
      fr: 'Responsabilité en matière de sécurité et de confidentialité'
    },
    {
      en: ' Endorsement #1 is as listed below:',
      fr: ' avenant no 1 est conforme à ce qui suit: '
    },
    {
      en: 'https://advocisinsurance.ca/contact/',
      fr: 'https://advocisassurance.ca/communiquez-avec-nous/'
    },
    {
      en: 'https://advocisinsurance.ca/exclusions/',
      fr: 'https://advocisassurance.ca/exclusions/'
    },
    {
      en: 'https://advocisinsurance.ca/wordings/',
      fr: 'https://advocisassurance.ca/libelles/'
    },
    {
      en: 'Policy Wordings',
      fr: 'Libelles'
    },
    {
      en: 'Why Choose Advocis?',
      fr: 'Pourquoi choisir Advocis?'
    },
    {
      en: 'Renew or Get a Quote',
      fr: 'Renouveler ou obtenir un devis'
    },
    {
      en: 'Who is this policy for?',
      fr: 'À qui cette police d’assurance est-elle destinée?'
    },
    {
      en: 'Individual E & O for financial advisors & financial planners',
      fr: 'Assurance de la responsabilité professionnelle pour les conseillers financiers et les planificateurs financiers'
    },
    {
      en: 'Includes personal corporation coverage (conditions apply)',
      fr: 'Comprenant une assurance de société personnelle (sous réserve des conditions applicables)'
    },
    {
      en: 'Coverage for life insurance/mutual funds, IIROC licensees, Unlicensed Planners/Fee for Service, Licensed Administrative Assistants',
      fr: 'Régime d’assurance-vie/les fonds communs de placement, les titulaires d’un permis de l’OCRCVM, les planificateurs non titulaires d’une licence/la rémunération à l’acte, adjoints administratifs agréés'
    },
    {
      en: 'Start your quote now by just entering the information below.',
      fr: 'Commencez votre demande de devis maintenant, en entrant simplement les renseignements ci-dessous.'
    },
    {
      en: 'Legal First Name',
      fr: 'Prénom officiel'
    },
    {
      en: 'First Name',
      fr: 'Prénom'
    },
    {
      en: 'Last Name',
      fr: 'Nom de famille'
    },
    {
      en: 'Your email',
      fr: 'Votre courriel'
    },
    {
      en: 'Coverage & Benefits',
      fr: 'Garantie et avantages'
    },
    {
      en: 'Exclusive Loyalty Discount (applies to all insurance coverages, Insuring Agreement A, B, C Trust & Estate Practitioners and Family Enterprise Advisors included, and D)',
      fr: 'Un rabais fidélité exclusif (applicable à toutes les protections d’assurance et Garanties A, B, C, y compris aux spécialistes en fiducie et en succession, ainsi qu’aux conseillers aux entreprises familiales et Garantie D)'
    },
    {
      en: 'In the last five years, have you been the recipient of any allegations of professional negligence in writing or verbally?',
      fr: 'Au cours des cinq dernières années, avez-vous fait l’objet d’allégations de négligence professionnelle par écrit ou verbalement?'
    },
    {
      en: 'Do you provide verbal/written advice to clients or receive direct client questions?',
      fr: 'Offrez-vous des conseils verbalement ou par écrit aux clients ou recevez-vous des questions directement des clients?'
    },
    {
      en: 'Do you sell or service products to clients?',
      fr: 'Vendez-vous des produits à des clients ou gérez-vous des produits pour des clients?'
    },
    {
      en: 'Do you sign any policy or endorsement documents?',
      fr: 'Signez-vous des documents relatifs à des polices ou à des avenants?'
    },
    {
      en: 'Do you have your own book of business or plan to own a book of business in the future?',
      fr: 'Avez-vous votre propre volume d’affaires (clientèle) ou planifiez-vous en faire l’acquisition dans l’avenir?'
    },
    {
      en: 'Have you ever owned your own book of business?',
      fr: 'Avez-vous déjà possédé votre propre volume d’affaires (clientèle)?'
    },
    {
      en: 'Do you have discretionary control over client transactions?',
      fr: 'Avez-vous un contrôle discrétionnaire sur les transactions des clients?'
    },
    {
      en: 'The applicant declares that, to the best of his/her knowledge and belief, the statements set forth herein are true and correct. The applicant agrees that all personal information that provided to the Broker is complete and accurate. Furthermore if any significant change in the condition of the applicant is discovered between the date of the application and the effective date of the policy, which would render this application inaccurate or incomplete, notice of such change will be reported immediately in writing to the insurer.',
      fr: 'Le proposant déclare qu’à sa connaissance, les déclarations contenues aux présentes sont véridiques et exactes. Le proposant convient que tous les renseignements personnels qu’il a fournis au courtier sont complets et exacts. En outre, si un changement important dans la situation du proposant est découvert entre la date de la proposition et la date d’entrée en vigueur de la police, ce qui rendrait cette proposition inexacte ou incomplète, un avis de ce changement sera signalé immédiatement par écrit à l’assureur.'
    },
    {
      en: 'How would you like to pay your premiums?',
      fr: 'Comment voulez-vous payer vos primes?'
    },
    {
      en: 'Group Benefits',
      fr: 'Avantages collectifs'
    },
    {
      en: 'Term Deposits',
      fr: 'Dépôts à terme'
    },
    {
      en: 'Loading payment page',
      fr: 'Chargement de la page de paiement'
    },
    {
      en: 'Failed to load payment page after',
      fr: 'Failed to load payment page after'
    },
    {
      en: 'attempts',
      fr: 'tentatives'
    },
    {
      en: 'attempt',
      fr: 'tentative'
    },
    {
      en: 'Retry',
      fr: 'Réessayer'
    },
    {
      en: 'Congratulations!',
      fr: 'Félicitations!'
    },
    {
      en: 'A payment link will follow shortly.',
      fr: 'Un lien de paiement suivra sous peu.'
    },
    {
      en: 'Opening Credit Card Payment Form',
      fr: 'Ouverture du formulaire de paiement par carte de crédit'
    },
    {
      en: "We've run into an error loading the payment page, please refresh the page and try again.",
      fr: 'Une erreur s’est produite pendant le chargement de la page de paiement, veuillez actualiser la page et réessayer.'
    },
    {
      en: 'Your policy will be reviewed by our brokerage team and you will be contacted to finalize your coverage within 72 hours. For any inquiries on this policy please contact us at ',
      fr: 'Notre équipe de courtage passera en revue vos renseignements et communiquera avec vous dans les 72 heures pour finaliser votre couverture. Pour toute question concernant cette police, veuillez nous appeler au '
    },
    {
      en: 'Your policy application has been received by our brokerage team. Our team will review your information.',
      fr: 'Notre équipe de courtage a reçu votre demande de police. Notre équipe passera en revue vos renseignements.'
    },
    {
      en: 'Client ID',
      fr: 'Identifiant du client'
    },
    {
      en: 'Client Email',
      fr: 'Courriel du client'
    },
    {
      en: 'Policy ID',
      fr: 'Identifiant de la police'
    },
    {
      en: 'Payment Canceled',
      fr: 'Paiement annulé'
    },
    {
      en: 'Payment Error',
      fr: 'Erreur de paiement'
    },
    {
      en: 'Transaction Error, Please try again.',
      fr: 'Erreur de transaction, veuillez réessayer.'
    },
    {
      en: 'Transaction Canceled, Please try again.',
      fr: 'Transaction annulée, veuillez réessayer.'
    },
    {
      en: 'Monthly',
      fr: 'Mensuellement'
    },
    {
      en: 'Yearly',
      fr: 'Annuellement'
    },
    {
      en: 'Please wait',
      fr: 'Veuillez patienter'
    },
    {
      en: 'Please do not reload the page while the icon is spinning',
      fr: 'Veuillez ne pas recharger la page pendant que l’icône tourne'
    },
    {
      en: 'Updating Client Status',
      fr: 'Mise à jour du statut du client'
    },
    {
      en: 'Updated Client Status',
      fr: 'Statut du client mis à jour'
    },
    {
      en: 'Inserting Transactions',
      fr: 'Insertion des transactions'
    },
    {
      en: 'Inserted Transactions',
      fr: 'Transactions insérées'
    },
    {
      en: 'Sending Email with Certificate Information',
      fr: 'Envoi d’un courriel contenant des renseignements sur le certificat'
    },
    {
      en: 'Sent certificate email.',
      fr: 'Certificat envoyé par courriel.'
    },
    {
      en: 'Redirecting',
      fr: 'Redirection'
    },
    {
      en: 'Done!',
      fr: 'C’est fait!'
    },
    {
      en: 'Purchase Completed!',
      fr: 'Achat terminé!'
    },
    {
      en: 'Thank you for selecting Advocis Broker Services Inc. as your insurance broker.',
      fr: 'Merci d’avoir choisi Services Aux Courtiers Advoics Inc. comme courtier d’assurance.'
    },
    {
      en: 'To assist you in managing your policy, our secure portal provides you with access to all your insurance documents.',
      fr: ' '
    },
    {
      en: 'An email with sign up instructions will be sent to you shortly.',
      fr: `Un courriel contenant vos certificats d'assurance responsabilité professionnelle vous serez envoyés.`
    },
    {
      en: 'We look forward to assisting you with all your insurance needs.',
      fr: `Nous nous réjouissons de pouvoir vous aider à répondre à tous vos besoins en matière d’assurance`
    },
    {
      en: 'Generating and Attaching Certificate for Policy ',
      fr: 'Génération d’un certificat joint à la police d’assurance '
    },
    {
      en: 'Automatic Security & Privacy insurance protection with limits up to $250,000 in coverage',
      fr: 'Une assurance responsabilité en matière de sécurité et de vie privée d’office, avec des limites de garantie à concurrence de 250 000 $'
    },
    {
      en: 'Learn More',
      fr: 'En Savoir Plus'
    },
    {
      en: 'Zero Deductible',
      fr: 'Sans franchise'
    },
    {
      en: 'If you have a claim involving sales and service of a life insurance product or a mutual fund investment, your claim will not be subject to a deductible.',
      fr: 'Si vous avez une réclamation concernant la vente ou le service d’un produit d’assurance-vie ou d’un investissement dans un fonds commun de placement, celle-ci ne fera pas l’objet d’une franchise.'
    },
    {
      en: 'The Advocis Advantage',
      fr: 'L’avantage Advocis'
    },
    {
      en: 'Our program for financial advisors and planners offers up to $10 million of coverage.',
      fr: 'Notre programme pour les conseillers et les planificateurs financiers permet de bénéficier d’une protection pouvant aller jusqu’à 10 millions de dollars.'
    },
    {
      en: 'We work with our partners throughout the year to enhance the coverage offerings of our program, so that your coverage evolves and grows with changes in our industry.',
      fr: 'Nous collaborons avec nos partenaires tout au long de l’année afin d’améliorer les offres de notre programme, pour que votre couverture évolue et grandisse en fonction des changements de notre secteur d’activité.'
    },
    {
      en: 'A partnership with Advocis Broker Services goes beyond just having access to industry leading risk management E&O coverage. Through our affiliation with Advocis, Advisors will also be able to register for professional practice development courses that will help satisfy all of your continuing education requirements.',
      fr: 'Un partenariat avec les Services aux courtiers Advocis va au-delà du simple accès à une garantie erreurs et omissions de premier ordre en matière de gestion des risques. Grâce à notre affiliation avec Advocis, les conseillers pourront également s’inscrire à des cours de perfectionnement professionnel qui les aideront à satisfaire à toutes leurs exigences en matière de formation continue.'
    },
    {
      en: 'Get a Quote',
      fr: 'Obtenir un devis'
    },
    {
      en: 'We’re going to help you get a renewal for E&O coverage as quickly as possible!',
      fr: 'Nous allons vous aider à obtenir un devis pour votre assurance erreurs et omissions dans les meilleurs délais!'
    },
    {
      en: 'We’re going to help you get a quote for E&O coverage as quickly as possible!',
      fr: 'Nous allons vous aider à obtenir un devis pour votre assurance erreurs et omissions dans les meilleurs délais.'
    },
    {
      en: 'To start, we just need a few things from you. Fill out the simple questions below and we’ll get you set up in no time at all.',
      fr: 'Pour commencer, nous avons besoin de quelques renseignements vous concernant. Veuillez répondre aux questions ci-dessous et nous vous préparerons votre devis.'
    },

    {
      en: "What's your first name?",
      fr: 'Quel est votre prénom?'
    },
    {
      en: "What's your middle name?",
      fr: 'Quel est votre second prénom?'
    },
    {
      en: "What's your last name?",
      fr: 'Quel est votre nom de famille?'
    },
    {
      en: "What's your email?",
      fr: 'Quel est votre courriel?'
    },
    {
      en: 'Where is your Province of Residence?',
      fr: 'Quelle est votre province de résidence?'
    },
    {
      en: 'Are you licensed to sell products in Manitoba?',
      fr: 'Êtes-vous autorisé à vendre des produits dans la province du Manitoba?'
    },
    {
      en: 'When do you need your coverage to start?',
      fr: 'Quand souhaitez-vous que votre garantie commence?'
    },
    {
      en: 'Coverages',
      fr: 'Garanties'
    },
    {
      en: 'Security and Privacy Liability, Regulatory Proceeding Coverage, Privacy Breach Costs, Cyber Extortion Threat Coverage, Reward Payments Coverage',
      fr: 'Responsabilité en matière de sécurité et de confidentialité, Garantie - démarches réglementaires, Coûts des atteintes à la vie privée, Garantie - menaces de cyberextorsion, Garantie - paiements compensatoires'
    },
    {
      en: 'Endorsement ',
      fr: 'Approbation '
    },
    {
      en: 'Please select the coverage(s) you need from the choices below:',
      fr: 'Veuillez sélectionner la ou les garanties dont vous avez besoin parmi les choix ci-dessous:'
    },

    {
      en: 'Cyber/Security and Privacy Liability',
      fr: 'Responsabilité envers la sécurité/cybersécurité et la vie privée'
    },
    {
      en: 'Life Insurance / Mutual Funds',
      // en: 'Life Insurance',
      fr: 'Assurance-vie et fonds communs de placement'
      // fr: 'Assurance-vie'
    },
    {
      en: 'IIROC Licensees (Securities/Mutual Funds)',
      fr: 'Titulaires d’un permis de l’OCRCVM (valeurs mobilières et fonds communs de placement)'
    },
    {
      en: 'Unlicensed Planners/Fee for Service',
      fr: 'Planificateurs non-agréés et rémunération à l’acte'
    },
    {
      en: 'Licensed Administrative Assistants',
      fr: 'Adjoints administratifs agréés'
    },
    {
      en: 'Renew or Get a Quote Now',
      fr: 'Renouveler Ou Obtenir Un Devis'
    },
    { en: 'Go Back', fr: 'Retour' },
    { en: 'Hi', fr: 'Bonjour' },
    { en: 'An error has occurred.', fr: "Une erreur s'est produite." },
    { en: 'Contact a Broker', fr: 'Contacter un courtier' },
    // landing.js

    { en: 'Renew or Get a Quote Now', fr: 'Renouveler Ou Obtenir Un Devis' },

    // pricingDetails.js
    { en: 'Discounts', fr: 'Rabais' },

    // no translation
    { en: 'Pricing Details', fr: 'Détails sur les prix' },
    { en: 'Total Due Today:', fr: 'Total à payer aujourd’hui:' },
    { en: 'Coverage Premiums', fr: 'Primes d’assurance' },
    { en: 'New Advisor Discount', fr: 'Rabais aux nouveaux conseillers' },

    // Coupon.js
    { en: 'Have a Corporate Code?', fr: 'Avez-vous un code d’entreprise?' },
    { en: 'Enter it here:', fr: 'Saisissez-le ici:' },
    { en: 'Apply Code', fr: 'Appliquer le code' },
    {
      en: 'If you are contracted with a firm that participates in the Advocis Corporate Partnership/Corporate Sponsorship Program, please enter the code provided by this organization. If you have received a code at an ABS or Advocis event, please enter that code.',
      fr: 'Si vous travaillez sous contrat avec une entreprise participant au programme de partenariat d’entreprise/parrainage d’entreprise d’Advocis, veuillez indiquer le code fourni par cette entreprise. Si vous avez reçu un code lors d’un événement ABS ou Advocis, veuillez saisir ce code.'
    },

    // no translation
    { en: 'Invalid Code entered', fr: 'Code invalide saisi' },
    { en: 'Code Successfully applied', fr: 'Code utilisé avec succès' },

    // coverages.js
    {
      en: 'Please note this coverage is not automatically included with your E&O policy and must be purchased separately',
      fr: 'Veuillez noter que cette protection n’est pas incluse d’office dans votre assurance de la responsabilité professionnelle et qu’elle doit être achetée séparément'
    },
    {
      en: 'You must hold a valid designation. Please select from the list below',
      fr: 'Vous devez être titulaire d’un titre valide. Veuillez choisir un titre dans la liste ci-dessous'
    },

    // coveragesdata,js
    { en: 'Other Designations', fr: 'Autres titres' },
    { en: 'Directors and Officers', fr: 'Administrateurs et dirigeants' },
    { en: 'Cyber/Privacy and Network', fr: 'Cybersécurité, protection des renseignements personnels et réseau' },

    {
      en: 'Basic Cyber coverage ($25,000 limit) is included at no additional cost with all policies, except for Licensed Administrative Assistants. For an additional premium, higher coverage can be purchased.',
      fr: 'L’assurance de cybersécurité (limite de 25 000 $) est incluse sans frais supplémentaires dans toutes les polices, sauf pour les adjoints administratifs agréés. Vous pouvez accroître la limite moyennant une prime supplémentaire.'
    },
    {
      en: 'Please note Security & Privacy is not offered as a standalone insurance product.',
      fr: 'Cybersécurité, protection des renseignements personnels et réseau'
    },

    //discountsdata.js
    { en: 'Are you a current Advocis member?', fr: 'Êtes-vous actuellement membre d’Advocis?' },
    {
      en: 'What year were you first licensed for Life and/or Mutual Funds?',
      fr: 'En quelle année avez-vous reçu votre premier permis d’agent d’assurance-vie ou de fonds communs de placement?'
    },

    // no translation
    { en: 'Enter your Advocis Member ID:', fr: 'Votre numéro de membre Advocis:' },

    // coupon.js
    {
      en: 'If you are contracted with a firm that participates in the Advocis Corporate Partnership/Corporate Sponsorship Program, please enter the code provided by this organization. If you have received a code at an ABS or Advocis event, please enter that code.',
      fr: 'Si vous travaillez sous contrat avec une entreprise participant au programme de partenariat d’entreprise/parrainage d’entreprise d’Advocis, veuillez indiquer le code fourni par cette entreprise. Si vous avez reçu un code lors d’un événement ABS ou Advocis, veuillez saisir ce code.'
    },

    // no translation
    { en: 'Invalid Code entered', fr: 'code invalide entré' },

    // formbottomnav.js
    { en: 'Continue', fr: 'Continuer' },
    { en: 'Required Fields', fr: 'Champs obligatoires' },
    // review.js
    { en: 'Edit', fr: 'Modifier' },
    {
      en: 'https://advocisinsurance.ca/coverages-a-b-c-d/#A',
      fr: 'https://advocisassurance.ca/garanties/#A'
    },
    {
      en: 'https://advocisinsurance.ca/coverages-a-b-c-d/#B',
      fr: 'https://advocisassurance.ca/garanties/#B'
    },
    {
      en: 'https://advocisinsurance.ca/coverages-a-b-c-d/#C',
      fr: 'https://advocisassurance.ca/garanties/#C'
    },
    {
      en: 'https://advocisinsurance.ca/coverages-a-b-c-d/#D',
      fr: 'https://advocisassurance.ca/garanties/#D'
    },
    {
      en: 'https://advocisinsurance.ca/coverages-a-b-c-d/#P',
      fr: 'https://advocisassurance.ca/garanties/#P'
    },
    // review.js
    {
      en: 'Review',
      fr: 'Vérification'
    },
    { en: 'Coverages:', fr: 'Garanties' },

    // no translation
    { en: 'Coverage Start Date:', fr: 'Date de début de la couverture :' },
    { en: 'Limit', fr: 'Limite' },
    { en: 'Discounts:', fr: 'Rabais' },

    // footer.js
    { en: 'Talk To A Broker', fr: 'Parler à un courtier' },
    { en: 'en-CA', fr: 'fr-CA' },
    { en: 'About Us', fr: 'À propos de nous' },
    { en: 'All Rights Reserved.', fr: 'Tous les droits sont réservés.' },
    { en: 'Copyright © Advocis Broker Services', fr: "Droits d'auteur © Services aux courtiers Advocis" },
    { en: 'Certified Financial Planner, CFP ®', fr: 'Planificateur financier agréé, CFP ®' },
    { en: 'Chartered Life Underwriter, CLU ®', fr: 'Souscripteur vie agréé, CLU ®' },
    { en: 'Certified Health Insurance Specialist, CHS™', fr: 'Spécialiste certifié en assurance maladie, CHS™' },
    { en: 'Chartered Financial Consultant, CH.F.C.', fr: 'Conseiller financier agréé, CH.F.C.' },
    { en: 'Trust and Estate Practitioners, TEP', fr: 'Professionnels des fiducies et des successions, TEP' },
    { en: 'Canadian Investment Management, CIM', fr: 'Gestion des placements canadiens, CIM' },
    { en: 'Fellow of the Canadian Securities Institute, FCSI®', fr: 'Fellow de l’Institut canadien des valeurs mobilières, FCSI®' },
    { en: 'Registered Financial Planner, R.F.P.', fr: 'Planificateur financier enregistré, R.F.P.' },
    { en: 'Financial Divorce Specialist, FDS', fr: 'Spécialiste en finances et divorce, FDS' },
    { en: 'Family Enterprise Advisor, FEA', fr: 'Conseiller en entreprise familiale, FEA' },
    { en: 'Personal Financial Planner, PFP ®', fr: 'Planificateur financier personnel, PFP ®' },

    // checkout
    { en: 'Checkout', fr: 'Caisse de sortie' },
    { en: 'Declarations', fr: 'Déclarations' },
    {
      en: 'In the last five years, have you or any of your employees been the recipient of any allegations of professional negligence in writing or verbally?',
      fr: 'Au cours des cinq dernières années, avez-vous vous-même (ou l’un de vos employés a-t-il) fait l’objet d’allégations de négligence professionnelle par écrit ou verbalement?'
    },
    {
      en: 'Are you or any of your employeees aware of any facts, circumstances or situations which may reasonably give rise to a claim, other than as advised above?',
      fr: 'Est-ce que vous (ou l’un de vos employés) êtes au courant de faits, de circonstances ou de situations qui pourraient raisonnablement donner lieu à une réclamation, à l’exception de ce qui est indiqué ci-dessus?'
    },
    {
      en: 'In the last five years, has there been, or is there now, pending against you any suit(s), proceedings, claim(s), fact(s), or situations(s) that would have been covered by this insurance?',
      fr: 'Y a-t-il eu, au cours des cinq dernières années,  ou y a-t-il actuellement des poursuites, des procédures, des réclamations, des faits ou des situations qui auraient été couverts par la présente assurance?'
    },
    {
      en: 'Have you ever had any license suspended or cancelled by a regulatory body for improper conduct?',
      fr: 'L’un de vos permis a-t-il déjà été suspendu ou résilié par un organisme de réglementation pour conduite répréhensible?'
    },
    {
      en: 'Are you currently under investigation/review by a regulatory body for improper conduct?',
      fr: 'Faites-vous actuellement l’objet d’une enquête ou d’un examen de la part d’un organisme de réglementation pour conduite répréhensible?'
    },
    {
      en: 'Have you ever been charged with or convicted of a dishonest or fraudulent act?',
      fr: 'Avez-vous déjà été accusé ou reconnu coupable d’un acte malhonnête ou frauduleux?'
    },
    {
      en: 'Have you ever been found guilty of any violation of Federal or Provincial insurance or securities law or regulation?',
      fr: 'Avez-vous déjà été reconnu coupable d’une infraction à la législation ou à la réglementation fédérale ou provinciale en matière d’assurance ou de valeurs mobilières?'
    },
    {
      en: 'If you have answered Yes to any of the questions above, please provide additional information',
      fr: "Si vous avez répondu Oui à l'une des questions ci-dessus, veuillez fournir des informations supplémentaires"
    },

    // information.js
    { en: 'Firm Information', fr: 'Renseignements sur l’entreprise' },
    {
      en: 'Please indicate which of these firms require their names to be shown on your Certificate of Insurance (for vicarious liability coverage).',
      fr: 'Veuillez indiquer lesquelles de ces entreprises demandent que leurs raisons sociales soient inscrites sur votre certificat d’assurance (aux fins de l’assurance de responsabilité du fait d’autrui).'
    },
    { en: 'All your firms', fr: 'Toutes vos entreprises' },
    { en: 'Firms on your certificate', fr: 'Les entreprises inscrites sur votre certificat' },
    { en: 'Street Address', fr: 'Adresse municipale' },
    { en: 'City', fr: 'Ville' },
    { en: 'Address of Insured', fr: 'Adresse de l’assuré' },
    { en: 'Province', fr: 'Province' },
    { en: 'Postal Code', fr: 'Code postal' },
    { en: 'Required Fields', fr: 'Champs obligatoires' },
    { en: 'Important Notice', fr: 'Remarque importante' },

    { en: 'Yes, 1 firm', fr: 'Oui, une entreprise' },
    { en: 'Yes, more than 1 firm', fr: 'Oui, plus d’une entreprise' },
    {
      en: 'Legal Name of Incorporated Firm. Please make sure to enter the full legal name e.g. 192168 Ontario Inc. operating as John Smith Financial',
      fr: 'Raison sociale de l’entreprise constituée en personne morale. Veuillez vous assurer d’indiquer la raison sociale en entier, par ex. 192168 Ontario Inc. faisant affaire sous le nom de Société financière Jean Tremblay'
    },
    {
      en: 'Is the corporation named above owned solely by you or jointly with your unlicensed spouse?',
      fr: 'La société constituée en personne morale ci-dessus est-elle détenue uniquement par vous ou encore conjointement avec votre conjoint(e) non agréé(e)?'
    },
    {
      en: 'Do you employ any other licensed or unlicensed agents, or accept business from other licensed agents? (Note that licensed administrative assistants are considered “licensed agents” whereas unlicensed administrative assistants are not considered “unlicensed agents”.)',
      fr: 'Employez-vous un ou plusieurs autres agents, agréés ou non, ou acceptez-vous des affaires d’autres agents agréés? (Veuillez noter que les adjoints administratifs agréés sont considérés comme étant des « agents agréés », tandis que ceux qui ne le sont pas ne sont pas considérés comme étant des «agents non-agréés».)'
    },

    // licensing.js
    { en: 'Licensing', fr: 'Permis' },
    { en: 'Life Insurance/Mutual Funds', fr: 'Assurance-vie/Fonds communs de placement' },
    { en: 'IIROC Licenses (Securities/Mutual Funds)', fr: 'Permis de l’OCRCVM (valeurs mobilières et fonds communs de placement)' },
    { en: 'Please select the areas in which you are licensed:', fr: 'Veuillez sélectionner les domaines dans lesquels vous êtes agréé:' },

    { en: 'Life Insurance', fr: 'Assurance-vie' },
    { en: 'Accident and Sickness', fr: 'Accidents et maladie' },
    { en: 'Mutual Funds', fr: 'Fonds commun de placement' },
    { en: 'Segregated Funds', fr: 'Fonds distinct' },
    { en: "GIC's", fr: 'CPG' },
    { en: 'GICs', fr: 'CPG' },
    {
      en: 'Security and Privacy Liability. Regulatory Proceeding Coverage, Privacy Breach Costs, Cyber Extortion Threat Coverage, Reward Payments Coverage',
      fr: 'Responsabilité en matière de sécurité et de confidentialité, Garantie - démarches réglementaires, Coûts des atteintes à la vie privée, Garantie - menaces de cyberextorsion, Garantie - paiements compensatoires'
    },
    { en: 'Pension Plans (incl. RRSP and RRIF)', fr: 'Régimes de retraite (y compris. REER et FERR)' },
    { en: 'Securities', fr: 'Valeurs mobilières' },
    { en: 'Income Tax Preparation', fr: 'Préparation de déclarations de revenus' },
    { en: 'Executor of Estate', fr: 'Exécuteur testamentaire' },
    { en: 'Notary Public', fr: 'Notaire' },
    { en: 'Commissioner of Oaths', fr: 'Commissaire à l’assermentation' },
    { en: 'Fee for Service Planning', fr: 'Services de planification (rémunération à l’acte)' },
    { en: 'Certified Divorce Specialist', fr: 'Spécialiste en divorce agréé' },
    { en: 'Other', fr: 'Autres' },

    { en: 'Coverage A', fr: 'Couverture A' },
    { en: 'Coverage B', fr: 'Couverture B' },
    { en: 'Coverage C', fr: 'Couverture C' },
    { en: 'Coverage D', fr: 'Couverture D' },

    // review.js

    { en: 'Review and Consent', fr: 'Vérification et consentement' },
    { en: 'Acknowledgements', fr: 'Attestations' },
    {
      en: 'Please note that this is a claims-made policy. You must report any potential incident(s)/claim(s) to the Insurer prior to the expiry of the policy term. At such point as you cease practice in the industry, the policy provides for limited or no coverage beyond the termination date of your certificate of insurance. Options are available to extend the period beyond which you may report claims based on past professional services.',
      fr: 'Veuillez prendre note qu’il s’agit d’une police sur la base des réclamations présentées. Vous devez déclarer tout incident possible ou toute réclamation potentielle à l’assureur avant la date d’échéance de la police. Au moment où vous cessez d’exercer dans le secteur, la police prévoit une garantie limitée ou aucune couverture au-delà de la date de résiliation de votre certificat d’assurance. Il est possible de prolonger la période au cours de laquelle vous pouvez déposer des réclamations fondées sur des services professionnels antérieurs.'
    },
    {
      en: 'The undersigned applicant declares that, to the best of his/her knowledge and belief, the statements set forth herein are true and correct and that reasonable efforts have been made to obtain sufficient information to facilitate the proper and accurate completion of this application form. The undersigned further agrees that if any significant change in the condition of the applicant is discovered between the date of the application and the effective date of the policy, which would render this application inaccurate or incomplete, notice of such change will be reported immediately in writing to the insurer. Although the signing of this application form does not bind the applicant to purchase the insurance, the undersigned agrees that this form and the information furnished herein shall be the basis of the contract should a policy be issued, and this application will become part of the policy.',
      fr: 'Le proposant soussigné déclare que dans la mesure de ses connaissances, les déclarations contenues dans les présentes sont véridiques et justes et que tout effort raisonnable a été déployé pour obtenir suffisamment d’information afin de faciliter la préparation correcte et pertinente du présent formulaire de proposition. Le soussigné convient également que si un changement important dans l’état du proposant est découvert entre la date de la proposition et la date d’entrée en vigueur de la police, ce qui rendrait cette proposition inexacte ou incomplète, un avis de ce changement sera signalé immédiatement par écrit à l’assureur. Bien que la signature du présent formulaire de demande n’engage pas le proposant à souscrire l’assurance, le soussigné accepte que le présent formulaire et les renseignements qu’il contient constituent la base du contrat si une police est émise, et la présente proposition fera partie de la police.'
    },
    { en: 'Personal Information Consent', fr: 'Consentement à l’égard des renseignements personnels' },
    {
      en: ' As part of my application for insurance, I hereby consent to the brokerage firm named below (the "Broker") and the Advocis Protective Association collecting, using and disclosing personal information* required for purposes of considering my application for new or renewal insurance coverage and of administering the professional liability insurance program sponsored by the Advocis Protective Association.',
      fr: 'Par les présentes et dans le cadre de ma proposition d’assurance, je permets au cabinet de courtage nommé ci-dessous (le « courtier ») et à l’Association de protection Advocis de recueillir, d’utiliser et de divulguer les renseignements personnels* requis pour l’évaluation de ma demande d’une nouvelle police d’assurance ou d’un renouvellement et pour la gestion du programme d’assurance de responsabilité professionnelle parrainé par l’Association de protection Advocis.'
    },
    {
      en: 'The Broker, on behalf of the Advocis Protective Association, is authorized to collect, use, and disclose personal information and provide such personal information to third parties, as required, including insurance companies, for the purposes of program administration, insurance requirements and all related activities including the marketing of the APA program. The Broker may also be required or permitted to disclose such personal information pursuant to relevant privacy or other laws.',
      fr: 'Le courtier, au nom de l’Association de protection Advocis, est autorisé à recueillir, à utiliser et à communiquer des renseignements personnels et à fournir ces renseignements personnels à des tiers, au besoin, y compris à des compagnies d’assurance, aux fins de l’administration du programme, des exigences en matière d’assurance et de toutes les activités connexes, y compris le marketing du programme APA. Le courtier peut également être tenu ou autorisé à divulguer ces renseignements personnels conformément aux lois pertinentes sur la confidentialité ou à d’autres lois.'
    },
    {
      en: 'The undersigned applicant also agrees that their personal information, including any email address, telephone and fax number, may be shared by The Financial Advisors Association of Canada (“TFAAC”) and TFAAC group entities operating either under the brand name Advocis, or as separate legal entities ("TFAAC" Group Entity) for the purpose of marketing, including telemarketing, the products and services of 1{")"} TFAAC Group Entity, including, but not limited to, the provisions of information on APA and other memberships, education programs, conferences, Advocis Corporate Partnership/Corporate Sponsorship program and regulatory issues and 2{")"} third parties that may be of interest to you. For further information about the TFAAC Privacy Policy, please refer to the Advocis.ca website.',
      fr: 'Le proposant soussigné accepte également que ses renseignements personnels, y compris son adresse courriel, son numéro de téléphone et son numéro de télécopieur, puissent être communiqués à l’Association des conseillers en finances du Canada (« ACFC ») et aux entités du groupe de l’ACFC exploitées sous le nom de marque Advocis ou en tant qu’entité juridique distincte (entité du groupe de l’« ACFC ») aux fins de marketing, y compris de télémarketing, des produits et services de 1) l’entité du groupe de l’ACFC, y compris, mais sans s’y limiter, les renseignements sur l’APA et d’autres adhésions, les programmes de formation, les conférences, le programme de partenariat d’entreprise/parrainage d’entreprise d’Advocis, et les questions réglementaires et 2) les tiers susceptibles de vous intéresser. Pour de plus d’informations sur la politique de confidentialité de l’ACFC, veuillez consulter le site Web, Advocis.ca.'
    },
    {
      en: "If I wish to review personal information* pertaining to my application or policy maintained by the Broker, obtain copies of the Broker's privacy policies or standards, or make other enquiries or express concerns to the Broker, I understand that I may do so by contacting the Broker’s Privacy Officer: Advocis Broker Services Inc. 700-10 Lower Spadina Ave Toronto ON M5V 2Z2.",
      fr: 'Si je souhaite revoir les renseignements personnels relatifs à ma proposition ou à ma police tenue par le courtier, obtenir des exemplaires des politiques ou des normes en matière de confidentialité du courtier, déposer toute requête ou faire part d’une préoccupation au courtier, je comprends que je peux le faire en communiquant avec le responsable de la confidentialité du courtier: Advocis Services Aux Courtiers 390 Queens Quay W. Suite 210, Toronto, Ontario M5V 3A2.'
    },
    {
      en: '* Personal information has the meaning given it by the "Personal Information Protection and Electronic Documents Act."',
      fr: '* La définition de renseignements personnels est celle donnée dans la Loi sur la protection des renseignements personnels et les documents électroniques.'
    },
    {
      en: 'I agree that all personal information that I provide to the Broker is complete and accurate, and I consent to the collection, use, and disclosure of the personal information as set forth above.',
      fr: 'J’affirme que tous les renseignements personnels que je fournis au courtier sont complets et exacts et je consens à ce que lesdits renseignements personnels soient recueillis, utilisés et divulgués, comme il est décrit ci-dessus.'
    },
    { en: 'Start Over', fr: 'Recommencer' },
    { en: 'Agree to terms', fr: 'Accepter les conditions' },

    // payment.js
    {
      en: 'Your policy request has been received and you will be contacted by our office to complete this purchase as per Quebec legislation',
      fr: 'Nous avons reçu votre demande de police et nous communiquerons avec vous pour conclure l’achat en vertu de la législation québécoise'
    },
    { en: 'Generating a Quote', fr: 'Préparation de devis' },
    { en: 'Generating a Quote, please wait', fr: 'Nous sommes en train de préparer un devis, veuillez patienter' },
    { en: 'Start Over', fr: 'Recommencer' },
    {
      en: "We've run into an error loading a new Quote, please refresh the page and try again.",
      fr: 'Une erreur s’est produite pendant le chargement du nouveau devis, veuillez actualiser la page et réessayer.'
    },
    { en: 'Reload', fr: 'Actualiser' },
    {
      en: 'Your application has been referred to an underwriter for review. Once the review has been completed we will contact you with the next steps.',
      fr: 'Votre demande a été transmise à un assureur à des fins d’examen. Une fois l’examen terminé, nous communiquerons avec vous pour vous expliquer les prochaines étapes.'
    },

    // policyDetails.js
    { en: 'Policy Details', fr: 'Détails de la police' },
    { en: 'Coupon Code:', fr: 'Code de coupon:' },
    { en: 'Advocis Member Discount', fr: 'Rabais de membre d’Advocis' },
    { en: 'Total Premium Due', fr: 'Prime totale à payer' },

    // unknown
    { en: 'Client Error', fr: 'Erreur de dossier client' },
    { en: 'We were unable to find or create a client record.', fr: 'La recherche ou la création du dossier client a échoué.' },
    { en: 'Please try again.', fr: 'Veuillez réessayer' },
    { en: 'OK', fr: 'OK' },
    { en: 'Start Over', fr: 'Recommencer' },

    { en: 'Select a Province...', fr: 'Veuillez choisir une province...' },
    { en: 'AB', fr: 'Alb.' },
    { en: 'BC', fr: 'C.-B.' },
    { en: 'MB', fr: 'Man.' },
    { en: 'NB', fr: 'N.-B.' },
    { en: 'NL', fr: 'T.-N.-L.' },
    { en: 'NS', fr: 'N.-É.' },
    { en: 'NU', fr: 'Nt' },
    { en: 'NT', fr: 'T.N.-O.' },
    { en: 'ON', fr: 'Ont.' },
    { en: 'PE', fr: 'Î.-P.-É.' },
    { en: 'QC', fr: 'Qc' },
    { en: 'SK', fr: 'Sask.' },
    { en: 'YT', fr: 'Yn' },

    { en: 'Please indicate where you are licensed:', fr: 'Veuillez indiquer où vous êtes licencié:' },

    { en: 'Who are you?', fr: 'Qui êtes-vous?' },
    { en: 'Quote', fr: 'Devis' },

    // { en: "Coverage for activities relating to the conduct of the business of a licensed life agent or licensed accident and/or sickness agent or a representative registered with the Mutual Fund Dealers Association of Canada to sell Mutual Funds, Life Insurance, Segregated Funds, Accident & Sickness, Term Deposits, Group Benefits, GIC’s and more.", fr: "Cyber Suite Insurance relating to Privacy, Confidentiality, and Security Liability; Privacy Regulation Defense, Awards and Fines; Customer Care and Reputational Expenses." },
    {
      en: 'Coverage for those activities, relating to a licensed administrative assistant: Life Insurance, Annuities, Accident & Sickness, hospitalization or medical insurance policies, investments otherwise insured under the policy.',
      fr: 'Une couverture pour ces services concernant un adjoint administratif agréé: L’assurance-vie, les rentes, les polices d’assurance accidents et maladie, d’hospitalisation ou médicale, les placements autrement couverts par la police'
    },
    {
      en: 'Coverage for activities related to a securities registrant registered with the Investment Industry Regulatory Organization of Canada, including Securities, Income Tax Preparation, Executors, Notary Public, Commissioner of Oaths, Fee for Service Planning, Financial Divorce Specialists and more.',
      fr: 'Une assurance pour les activités relatives à une personne inscrite auprès de l’Organisme canadien de réglementation du commerce des valeurs mobilières, en ce qui a trait notamment aux valeurs mobilières et à la préparation de déclarations de revenus, ou à titre de liquidateur, de notaire, de commissaire à l’assermentation, de services de planification moyennant des honoraires, de spécialiste financier en divorce et plus encore.'
    },
    {
      en: 'Coverage for activities relating to the conduct of the business of a licensed life agent or licensed accident and/or sickness agent or a representative registered with the Mutual Fund Dealers Association of Canada to sell Mutual Funds, Life Insurance, Segregated Funds, Accident & Sickness, Term Deposits, Group Benefits, GIC’s and more.',
      fr: 'Une assurance pour les activités relatives à l’exercice de la profession de courtier d’assurance-vie ou d’assurance-accidents agréé ou de courtier d’assurance maladie agréé ou de représentant inscrit auprès de l’Association canadienne des courtiers de fonds mutuels l’autorisant à vendre des parts de fonds communs de placement, de l’assurance-vie, des parts de fonds distincts, de l’assurance-accidents et maladie, des dépôts à terme, de l’assurance collective, des certificats de placement garanti et plus encore.'
    },
    {
      en: 'Cyber Suite Insurance relating to Privacy, Confidentiality, and Security Liability; Privacy Regulation Defense, Awards and Fines; Customer Care and Reputational Expenses.',
      fr: 'Assurance série cybersécurité concernant la responsabilité envers la vie privée, la confidentialité et la sécurité; la défense en vertu du règlement sur la protection des renseignements personnels, les indemnités compensatoires et les amendes; les dépenses relatives au service à la clientèle et à la réputation.'
    },
    {
      en: 'Activities provided for a fee relating to financial advice and for which a license is not required. Activities relating to and including sales management and supervisory activities over individual advisors and planners in reference to the above.',
      fr: 'Les services fournis contre rémunération en rapport avec le conseil financier et pour lesquels un permis n’est pas requis. Les services concernant et incluant la gestion des ventes et les activités de supervision des conseillers et des planificateurs individuels en ce qui concerne ce qui mentionné ci-dessus.'
    },

    { en: 'Choose a Year...', fr: 'Veuillez choisir une année...' },
    { en: '2014 or Before', fr: '2014 Ou avant le' },
    { en: '2014', fr: '2014' },
    { en: '2015', fr: '2015' },
    { en: '2016', fr: '2016' },
    { en: '2017', fr: '2017' },
    { en: '2018', fr: '2018' },
    { en: '2019', fr: '2019' },
    { en: '2020', fr: '2020' },
    { en: '2021', fr: '2021' },
    { en: '2022', fr: '2022' },
    { en: '2023', fr: '2023' },
    { en: '2024', fr: '2024' },
    { en: '2025', fr: '2025' },
    { en: '2026', fr: '2026' },
    { en: '2027', fr: '2027' },
    { en: '2028', fr: '2028' },
    { en: '2029', fr: '2029' },
    { en: '2030', fr: '2030' },
    { en: '2031', fr: '2031' },
    { en: '2032', fr: '2032' },
    { en: '2033', fr: '2033' },
    { en: '2034', fr: '2034' },

    { en: 'Alberta', fr: 'Alberta' },
    { en: 'British Columbia', fr: 'Colombie britannique' },
    { en: 'Manitoba', fr: 'Manitoba' },
    { en: 'New Brunswick', fr: 'Nouveau-Brunswick' },
    { en: 'Newfoundland and Labrador', fr: 'Terre-Neuve-et-Labrador' },
    { en: 'Northwest Territories', fr: 'Territoires du Nord-Ouest' },
    { en: 'Nova Scotia', fr: 'Nouvelle-Écosse' },
    { en: 'Nunavut', fr: 'Nunavut' },
    { en: 'Ontario', fr: 'Ontario' },
    { en: 'Prince Edward Island', fr: 'Île-du-Prince-Édouard' },
    { en: 'Québec', fr: 'Québec' },
    { en: 'Saskatchewan', fr: 'Saskatchewan' },
    { en: 'Yukon Territory', fr: 'Territoire du Yukon' },
    {
      en: 'Email',
      fr: 'Courriel'
    },
    {
      en: 'Start date of coverage',
      fr: 'Date de début de la couverture'
    },
    {
      en: 'Coverage',
      fr: 'Couverture'
    },
    {
      en: 'Total Due',
      fr: 'Montant total dû'
    },
    {
      en: 'quote is valid for this web session only, prices subject to change',
      fr: "Le devis est valable pour cette session web uniquement, le prix est susceptible d'être modifié"
    },
    {
      en: 'Life Insurance / Mutual Funds - E&O',
      fr: 'Assurance-vie / Fonds communs de placement - E&O'
      // en: 'Life Insurance',
      // fr: 'Assurance-vie'
    },
    {
      en: 'You must hold a valid designation. Please select from the list below.',
      fr: "Vous devez être titulaire d'une désignation valide. Veuillez choisir dans la liste ci-dessous."
    },
    {
      en: 'Advocis member information',
      fr: "Informations pour/ sur les membres d'Advocis"
    },
    {
      en: 'Year you were first licensed',
      fr: "Année d'obtention de la première licence"
    },
    {
      en: 'Firm Info',
      fr: "Informations sur l'entreprise"
    },
    {
      en: 'Payment',
      fr: 'Paiement'
    },
    {
      en: 'All questions above',
      fr: 'Toutes les questions ci-dessus'
    },
    { en: '', fr: '' },
    { en: '123 Main Street', fr: '123 rue Principale' },
    {
      en: 'Your application has been referred to an underwriter for review. Once the review has been completed we will contact you with the next steps.',
      fr: ''
    },
    { en: 'Please  :', fr: '' },
    { en: 'per claim limit', fr: 'limite par réclamation' },

    // yes/no
    { en: 'Yes', fr: 'Oui' },
    { en: 'yes', fr: 'oui' },
    { en: 'No', fr: 'Non' },
    { en: 'no', fr: 'non' }
  ];
  const translation = haystack.find((string) => string.en === needle)?.fr;
  return translation ? translation : 'NEEDS TRANSLATION';
}

export function translateJSXElement(locale = 'EN', key) {
  // console.log("translateJSXElement", locale, key);
  const translationList = {
    'quote.WelcomeParagraph1': {
      fr: <></>,
      en: <></>
    },
    'page.Location': {
      fr: <></>,
      en: <></>
    },
    'header.menuItemAboutUs': {
      en: (
        <a href='https://advocisinsurance.ca/' target='_blank' rel='noopener noreferrer'>
          About Us
        </a>
      ),
      fr: (
        <a href='https://advocisassurance.ca/' target='_blank' rel='noopener noreferrer'>
          À propos de nous
        </a>
      )
    },
    'coverages.information': {
      en: (
        <p>
          For further inquiries please contact ABS Inc at <a href='mailto:info@absinc.ca'>info@absinc.ca</a> or 1-877-646-9888'
        </p>
      ),
      fr: (
        <p>
          Pour en savoir davantage, veuillez communiquer avec ABS inc. à l’adresse <a href='mailto:info@absinc.ca'>info@absinc.ca</a> ou au
          1-877-646-9888
        </p>
      )
    },
    'information.list': {
      en: (
        <>
          List below, <strong>one per line</strong>, any firms with which you are contracted to do business. (Maximum 250 characters)
        </>
      ),
      fr: (
        <>
          Énumérez ci-dessous, en indiquant une entreprise par ligne, toutes les entreprises avec lesquelles vous faites affaire en vertu d’une
          entente contractuelle. (250 caractères maximum)
        </>
      )
    },
    'locale.ownership': {
      en: (
        <>
          Do you hold an ownership interest in one or more <span className='bold underline'>incorporated</span> firms under which you will provide
          financial advisory services under our Life Insurance/Mutual Funds coverage?
        </>
      ),
      fr: (
        <>
          Détenez-vous des parts de capitaux propres d’une ou de plusieurs entreprises constituées en personne morale par le biais desquelles vous
          fournissez des services de conseil financier dans le cadre de notre garantie d’assurance-vie / de fonds communs de placement?
        </>
      )
    },
    'header.logo': {
      en: (
        <>
          <a href='/'>
            <img src='/img/svg/advocis-logo-new.svg' alt='Advocis Broker Services Logo' height='48' width='240' />
          </a>
        </>
      ),
      fr: (
        <>
          <a href='/'>
            <img src='/img/svg/advocis-logo-french.svg' alt='Advocis Broker Services Logo' height='48' width='240' />
          </a>
        </>
      )
    },
    'footer.logo': {
      en: (
        <a href='/'>
          <img src='/img/svg/logo-white-new.svg' alt='Advocis Broker Services Logo - White' />
        </a>
      ),
      fr: (
        <a href='/'>
          <img src='/img/svg/logo-white-french.svg' alt='Advocis Broker Services Logo - White' />
        </a>
      )
    },
    'information.oneFirmModal': {
      en: (
        <p>
          Provincial requirements in <span className='bold'>Alberta</span>, <span className='bold'>Saskatchewan</span>,{' '}
          <span className='bold'>New Brunswick</span>, <span className='bold'>Quebec</span> and <span className='bold'>Newfoundland</span> state that
          all incorporated entities must hold separate and distinct Corporate Errors & Omissions Insurance. If you and/or your incorporated entity are
          licensed in one or more of these provinces you may not list your incorporated entity under your personal E&O coverage.
        </p>
      ),
      fr: (
        <p>
          Les exigences de l'Alberta, de Saskatchewan, du Nouveau-Brunswick, du Québec et de Terre-Neuve-et-Labrador stipulent que toutes les entités
          constituées en personne morale doivent détenir une garantie erreurs et omissions d’entreprise distincte. Si vous et/ou votre entité
          constituée en personne morale détenez un ou plusieurs permis dans ces provinces, il vous est interdit d’indiquer votre entité constituée en
          personne morale dans le cadre de votre garantie erreurs et omissions personnelle.
        </p>
      )
    },
    'information.purchase': {
      en: (
        <p>
          Complete the purchase of your personal coverage here. Then,{' '}
          <a href='https://advocisinsurance.ca/personal-corporate-coverage/' target='_blank' rel='noreferrer'>
            click here
          </a>{' '}
          for all the information you need on how to acquire Corporate E&O. We will also provide you this information on completion of your purchase.
        </p>
      ),
      fr: (
        <p>
          Terminez l’achat de votre garantie personnelle ici. Puis,{' '}
          <a href='https://advocisinsurance.ca/personal-corporate-coverage/' target='_blank' rel='noreferrer'>
            cliquez ici
          </a>{' '}
          pour obtenir les informations dont vous avez besoin afin de savoir comment acquérir une garantie erreurs et omissions pour les entreprises.
          Nous vous fournirons également ces informations lorsque votre achat sera terminé.
        </p>
      )
    },
    'review.apaDeclarations': {
      en: (
        <>
          <h4>ADVOCIS PROTECTIVE ASSOCIATION (APA) DEDUCTIBLE FUND TRUST DECLARATIONS</h4>
          <p>
            This application constitutes insurance coverage provided by the Advocis Protective Association E&O Plan insurer and a Deductible Trust
            Fund managed by the Advocis Protective Association, consisting of Advocis Protective Association E&O Plan certificate holders’ “Deductible
            Fund Contributions”. The amounts of these contributions have been agreed upon by the insurer and serve to fund the difference between the
            certificate holder’s individual policy deductible and the first $100,000 of each claim made under the policy, payable by the Advocis
            Broker Services Inc. on behalf of the certificate holder. The Advocis Broker Services Inc.’s obligation to pay this part of each claim is
            limited by an annual aggregate equivalent to the total Deductible Fund contributions made by certificate holders. The Deductible Fund
            Contribution, forming part of each certificate holder’s total payment for this insurance coverage, is fixed, and shall not increase for
            any reason other than increases in coverage granted to the applicant, and as evidenced in the participant’s certificate of insurance.
          </p>
          <p>
            The undersigned applicant acknowledges and agrees that the Advocis Broker Services Inc. has no obligation to refund Deductible Fund
            Contributions, in whole or in part, made by the undersigned arising from favourable individual certificate holders’ loss experience, nor
            as a consequence of terminating insurance coverage under the Advocis Protective Association E&O plan at any time.{' '}
          </p>
          <p>
            The applicant further acknowledges that the Advocis Broker Services Inc. and or its Claims Committee may, from time to time, provide
            consultation to the plan Insurers or to the plan’s Claims Adjusting Firm in the context of industry-related advice intended to facilitate
            fair and expedited claims management.
          </p>
          <p>
            Such consultation shall not include advice with respect to matters concerning policy coverage or potential claims denials. For further
            information, please contact Advocis Broker Services Inc. or visit{' '}
            <a href='advocisinsurance.ca' target='_blank'>
              advocisinsurance.ca
            </a>
            .
          </p>
        </>
      ),
      fr: (
        <>
          <h4>CONDITIONS PARTICULIÈRES RELATIVES À LA FIDUCIE DU FONDS DE FRANCHISE DE L’ASSOCIATION DE PROTECTION ADVOCIS (APA)</h4>

          <p>
            La présente proposition constitue la garantie d’assurance fournie par l’assureur du Régime d’assurance erreurs et omissions de
            l’Association de protection Advocis, et une fiducie du fonds de franchise géré par l’Association de protection Advocis, se composant des
            cotisations au fonds de franchise des titulaires de certificats du Régime d’assurance erreurs et omissions de l’Association de protection
            Advocis. Les montants de ces cotisations ont été convenus par l’assureur et servent à financer la différence entre la franchise de la
            police individuelle du titulaire du certificat et la première tranche de 100 000 $ de chaque réclamation faite en vertu de la police,
            payable par Advocis Services Aux Courtiers au nom du titulaire du certificat. L’obligation d’Advocis Services Aux Courtiers de payer cette
            portion de chaque réclamation est limitée par le montant de garantie par année d’assurance équivalant au total des cotisations du fonds de
            franchise versées par les titulaires de certificats.
          </p>

          <p>
            La cotisation au fonds de franchise, qui fait partie du paiement total de chaque titulaire de certificat pour cette garantie d’assurance,
            est fixe et n’augmentera pour aucune autre raison que l’augmentation de la garantie accordée au proposant, et comme l’indique son
            certificat d’assurance.
          </p>

          <p>
            Le proposant soussigné reconnaît et accepte qu’Advocis Services Aux Courtiers n’a aucune obligation de rembourser, en tout ou en partie,
            les cotisations au fonds de franchise versées par le ou la soussigné(e) à la suite de la sinistralité favorable des titulaires de
            certificats individuels, ni par suite de la résiliation de l’assurance en vertu du Régime d’assurance erreurs et omissions de
            l’Association de protection Advocis, à quelque moment que ce soit.
          </p>

          <p>
            Le proposant accepte en outre qu’Advocis Services Aux Courtiers ou son comité des réclamations pourrait, de temps en temps, offrir des
            services de consultation, aux assureurs du régime ou à la firme d’experts en sinistres, en matière de conseils concernant l’industrie dans
            l’optique d’une gestion juste et diligente des réclamations
          </p>

          <p>
            Ces services de consultation n’incluront pas de conseils sur des questions relatives à la garantie de la police ou des refus de
            réclamations potentiels. Pour obtenir plus de renseignements, veuillez communiquer avec Advocis Services Aux Courtiers ou visitez le{' '}
            <a href='advocisinsurance.ca' target='_blank'>
              advocisinsurance.ca
            </a>
            .
          </p>
        </>
      )
    }
  };
  return translationList[key][locale ? locale.toLowerCase() : 'en'];
}
// export function toggleLocale(currentLocale) {
//   if (currentLocale === "EN") {
//     return "FR"
//   } else {
//     return "EN";
//   }
// }
